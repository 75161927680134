import * as React from "react";
import ROUTES from "../../platform/constants/routes";
import { byRoute } from "../../platform/decorators/routes";

import "./style.scss";
import { IPagination } from "src/platform/constants/interfaces";
import CatalogController, { ICatalog } from "../../platform/api/catalog";
import { Link } from "react-router-dom";
import Settings from "../../platform/services/settings";

interface IState {
  data: IPagination<ICatalog> | null;
  loading: boolean;
  currentPage: number;
}

@byRoute(ROUTES.CATALOG)
class Catalog extends React.Component {
  public state: IState = {
    data: null,
    loading: false,
    currentPage: 1,
  };

  private limit = 30;

  public componentDidMount() {
    this.fetchData();
  }

  private fetchData = async () =>
    this.setState({ loading: true }, async () => {
      const { data, currentPage } = this.state;
      const result = await CatalogController.List(currentPage, this.limit);
      if (data)
        result.data.itemList = [...data.itemList, ...result.data.itemList];
      this.setState({ data: result.data, loading: false });
    });

  public render() {
    const { data, loading } = this.state;

    return (
      <section className="I-G-page I-catalog-page">
        <h1 className="I-G-page-title">{Settings.translations.catalog}</h1>
        <div className="P-catalogs">
          {data &&
            data.itemList.map((item, index) => (
              <a
                key={index}
                className="P-catalog-item"
                href={item.name}
                target="_blank"
              >
                <img src={item.logo} alt="" />
              </a>
            ))}
        </div>
      </section>
    );
  }
}

export default Catalog;

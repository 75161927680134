import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import URLSearchParams from "@ungap/url-search-params";

import ROUTES from "../../platform/constants/routes";
import Categories from "./components/categories";
import Settings from "../../platform/services/settings";
import Storage from "../../platform/services/storage";
import WishController from "../../platform/api/wish";
import Search from "./components/search";
import { ICachedCategories } from "../../platform/api/category";
import { ICategoryList } from "./constants/interfaces";

import { IAuthQueryData } from "../../modules/login/constants/interfaces";
import { VerifyTypeEnum } from "../../modules/login/constants/enums";
import GuestWishList from "../../modules/wish-list/services/guest";
import HelperComponent from "../../platform/classes/helper-component";

import Logo from "../../assets/images/logo2.png";
import "./style.scss";

interface IState {
  categoryLists: ICategoryList[];
  cachedCategories: ICachedCategories[];
  loginOpen: boolean;
  categoriesOpen: boolean;
  profileMenuOpen: boolean;
  queryData: IAuthQueryData | null;
  search: string;
  mobileOpen: boolean;
  wishListBadge: number | null;
  storePath: string;
  isDownloadApp: boolean;

  searchField: boolean;
}

class Header extends HelperComponent<{}, IState> {
  public state: IState = {
    loginOpen: false,
    categoryLists: [],
    cachedCategories: [],
    categoriesOpen: false,
    profileMenuOpen: false,
    queryData: null,
    storePath: "",
    search: "",
    mobileOpen: false,
    wishListBadge: !Storage.profile ? GuestWishList.Ids.length : null,
    isDownloadApp: false,
    searchField: true,
  };

  public componentDidMount() {
    Storage.profile && this.wishListModify();
    window.routerHistory.listen(() =>
      this.safeSetState({
        categoryLists: [],
        categoriesOpen: false,
      })
    );

    // window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("wishlistmodify", this.wishListModify);
    window.addEventListener("addNewList", this.wishListModify);
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("wishlistmodify", this.wishListModify);
    window.removeEventListener("addNewList", this.wishListModify);
  }

  private wishListModify = async () => {
    if (!Storage.profile) {
      this.safeSetState({ wishListBadge: GuestWishList.Ids.length });
    }
    if (Storage.profile) {
      const { data } = await WishController.List();

      this.safeSetState({ wishListBadge: data.length });
    }
  };

  private openCategories = () => this.safeSetState({ categoriesOpen: true });
  private closeCategories = (e?: MouseEvent) => {
    e && e.stopPropagation();
    this.safeSetState({ categoriesOpen: false });
  };

  private changeCategoryLists = (categoryLists: ICategoryList[]) =>
    this.safeSetState({ categoryLists });
  private changeCachedCategories = (cachedCategories: ICachedCategories[]) =>
    this.safeSetState({ cachedCategories });


  public render() {
    const {
      categoriesOpen,
      categoryLists,
      cachedCategories,
      wishListBadge,
    } = this.state;

    return (
      <header>
        <div className="I-header-top">
          <Link to={ROUTES.HOME.MAIN} className="I-logo">
            <img src={Logo} alt="logo" />
          </Link>
          <Search
            fieldPosition={`I-header-search ${
              this.state.isDownloadApp ? "more-top" : ""
            }`}
            fieldAnimation={
              !this.state.searchField ? "search-field-animation" : ""
            }
          />
          <Link className="I-label-item" to={ROUTES.WISH_LIST.MAIN}>
            <i className="icon-wishlist" />
            {!!wishListBadge && (
              <span className="I-badge">
                {wishListBadge > 99 ? "9+" : wishListBadge}
              </span>
            )}
          </Link>
        </div>
        <div className="I-header-bottom">
          <a onClick={this.openCategories}>
            <span
              className={`I-burger ${categoriesOpen ? "I-burger-open" : ""}`}
            >
              <span />
              <span />
              <span />
            </span>
            {Settings.translations.all_categories}
          </a>

          <NavLink to={ROUTES.CATALOG} activeClassName="Q-active">
            {Settings.translations.catalog}
          </NavLink>
          <NavLink to={ROUTES.ABOUT} activeClassName="Q-active">
            {Settings.translations.about_us}
          </NavLink>
          <NavLink to={ROUTES.CONTACT} activeClassName="Q-active">
            {Settings.translations.contact_us}
          </NavLink>
        </div>
        {categoriesOpen && (
          <Categories
            categoryLists={categoryLists}
            cachedCategories={cachedCategories}
            onChange={this.changeCategoryLists}
            onCachedChange={this.changeCachedCategories}
            onClose={this.closeCategories}
          />
        )}
      </header>
    );
  }
}

export default Header;

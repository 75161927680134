// export default {
//   BASE_URL: "https://qapapi.armlon.co.uk/",
// };

export default {
  // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "http://localhost:4002/" :  "https://api.medicaldentalshop.com/"
  BASE_URL: "https://api.enosoul.am/",
  // BASE_URL: 'http://localhost:4002/',
  // https://api.MedicalDentalShop.com.am/
};

import Connection from "../services/connection";
import { IResponse, IPagination } from "../constants/interfaces";

const controller = "catalog";

export interface ICatalog {
  _id: string;
  name: string;
  logo: string;
  productCount: number;
}

class CatalogController {
  public static List = (
    pageNo: number,
    limit: number
  ): Promise<IResponse<IPagination<ICatalog>>> => {
    const result = Connection.GET({
      action: "list",
      query: { pageNo, limit },
      controller,
    });

    return result;
  };
}

export default CatalogController;

import * as React from "react";

import ROUTES from "../../platform/constants/routes";
import Settings from "../../platform/services/settings";

const contactText = (
  <>
    Հարգելի հաճախորդներ, <br /> <br />
    Շնորհակալություն ենք հայտնում մեր առաջարկած ծառայությունների նկատմամբ
    հետաքրքրություն ցուցաբերելու համար <br />
    Մենք աշխատում ենք բարձրացնել մեր կողմից առաջարկվող ծառայությունների որակը,
    ինչի համար կարևորում ենք հետադարձ կապը մեր հաճախորդների հետ: <br />
    Առաջացած հարցերի կամ խնդիրների դեպքում խնդրում ենք լրացնել ստորև նշված
    տողերը կամ զանգահարել <br />
    <b>+374 33 08 80 07, +374 94 08 80 07, +374 33 77 08 80 </b>
    հեռախոսահամարներով: <br /> <br />
    <address>
      Էնոսոուլ ՍՊԸ <br />
      0043, ՀՀ, ք․ Երևան, Շիրակի 9/2
      <br />
      Հեռ: +374 33 08 80 07 <br />
      Էլ.հասցե: info@enosoul.am <br />
    </address>
  </>
);

const businessText = (
  <>
    <ul>
      <li>
        Մենք ամբողջովին մեզ վրա ենք վերցնում Ձեր ընկերության մատակարարումների և
        գնումների կազմակերպումը:
      </li>
      <li>
        Մեր աշխատակիցները ոչ միայն ընդունում են Ձեր պատվերը, այլ նաև հետևում են
        յուրաքանչյուր պատվերին` սկսած գնումից մինչև առաքումը նշված հասցե:
      </li>
      <li>
        Ձեր գնումների հետ կապված փաստաթղթավորումը ամբողջությամբ կազմակերպում է
        մեր թիմը:
      </li>
    </ul>
    Մեր ընկերությունը ունի հատուկ պայմաններով համաձայնագրեր և պայմանագրեր շատ
    արտադրողների և վաճառողների հետ, ինչի շնորհիվ Դուք ևս կարող եք օգտվել մեր մեծ
    զեղչերից: Մուտք գործելով մեր կողմից առաջարկվող մեծածախ առեվտրի ցանց, դուք
    հնարավորություն կունենաք ներկայացնել Ձեր ապարնքը Հայաստանի ամբողջ մեծածախ եւ
    մանրածախ առեվտրի կետերում, ինչպես սեփական անվանման տակ,այնպես ել մեր
    ընկերության մասնագետի կողմից առաջարվող ապրանքանիշի ներքո: Ապահովում ենք
    ապրանքանիշի առաջխաղացումը` իրականացնելով արդյունավետ եւ հաշվարկային թվերի
    վրա հիմնված գովազդ:
    <br />
    <br />
    Եթե ցանկանում եք խուսափել ավելորդ ծախսերից, ապա այս ծառայությունը հենց Ձեզ
    համար է:
    <br />
    <br />
    Մեզ հետ համագոժակցելու դեպքում Դուք ստանում եք`
    <ul>
      <li>
        Անհրաժեշտ ապրանքների ստացման ամբողջական գործընթացի կազմակերպումը` սկսած
        պատվերից մինչև առաքում:
      </li>
      <li>Փաստաթղթաշրջանառության կազմակերպում</li>
      <li>Մեր զեղչերից օգտվելու հնարավորություններ</li>
      <li>Մեծածախ գներով ապրանքներ ձեռք բերելու հնարավորություններ</li>
      <li>Տրամադրում ենք շուկայական գների վերլուծություն</li>
      <li>Առաջարկում ենք ծախսերը նվազեցնելու տարբերակներ</li>
      <li>
        Ցանկության դեպքում տրամադրում ենք ապրանքների կատալոգներ և նմուշներ
      </li>
    </ul>
    Եթե հետաքրքրված եք մեր ծառայություններով, զանգահարեք 096 222322
    հեռախոսահամարով կամ գրեք{" "}
    <a href="mailto:business@Enosoul.am">business@Enosoul.am</a> էլ. հասցեին, և
    մեր աշխատակիցները սիրով կպատասխանեն Ձեզ:
  </>
);

const contractText = (
  <>
    1. Սույն պայմանագիրը (այսուհետ՝ Պայմանագիր) սահմանում և կարգավորում է
    &lt;&lt;Կրոմա Գրուպ&gt;&gt; ՍՊ ընկերության (այսուհետ նաև՝ Ընկերություն)
    Enosoul.am կայքով (այսուհետ նաև ՝ Կայք) Ընկերության կողմից մատուցվող
    ծառայությունների հետ կապված իրավահարաբերությունները:
    <br />
    <br />
    2. Մուտք գործելով Կայք, օգտագործելով Կայքի ցանկացած ծառայություն կամ
    ներբեռնելով ցանկացած բովանդակություն՝ ամբողջությամբ կամ դրա մի մասը Դուք,
    որպես օգտագործող (այսուհետ նաև՝ Օգտագործող, Դուք, Ձեզ, Ձեր, Հաճախորդ)
    հաստատում եք, որ ամբողջությամբ կարդացել և հասկացել եք Պայմանագիրը, ինչպես
    նաև Կայքում հրապարակված Ընկերության կողմից Ծառայությունների մատուցման այլ
    պայմանները (այսուհետ նաև՝ Պայմաններ կամ Պայմանագիր) և անվերապահորեն
    ընդունում եք դրանք, անկախ նրանից՝ Դուք Ընկերության կողմից մատուցվող
    Ծառայությունների հաճախորդ եք, Կայքի գրանցված օգտագործող, թե՝ ուղղակի
    այցելու:
    <br />
    <br />
    3. Ընկերությունն իրավունք ունի առանց նախապես ծանուցման, միակողմանի, ցանկացած
    պահի փոփոխել Պայմանագիրը: Այդ դեպքում փոփոխված Պայմանագիրը կտեղադրվի
    Կայքում՝ նշելով վերջին փոփոխության ամսաթիվը: Օգտագործողի պարտականությունն է
    Կայք այցելելիս ծանոթանալ Պայմանագրի փոփոխություններին (առկայության դեպքում):
    Փոփոխություններն ուժի մեջ են մտնում Կայքում տեղադրելու օրվանից եվ տարածվում
    են դրանից հետո ծագած հարաբերությունների վրա:
    <br />
    <br />
    4. Եթե Դուք համաձայն չեք Պայմանագրին, անհրաժեշտ է դադարեցնել Կայքի
    օգտագործումը: Կայքում գտնվելը, հաշիվ բացելը, Օգտագործող դառնալը և
    Ծառայություններից օգտվելը փաստում են Ձեր կողմից Պայմանագրի պայմանների
    անվերապահ ընդունումը եվ կնքումը:
    <br />
    <br />
    5. Կնքելով Պայմանագիրը՝ Դուք հաստատում եք, որ լրիվ գործունակ եք և իրավունք
    ունեք օգտվել Ծառայություններից: Եթե Դուք ներկայացնում եք իրավաբանական անձ,
    ապա Դուք հավաստում եք, որ իրավասու եք իրավաբանական անձի անունից օտվել
    Ծառայություններից: Համաձայնվելով սույն պայմաններին, Դուք ընդունում եք, որ
    Ընկերությունը չի կարող ստուգել Ձեր տված տեղեկությունները և տվյալները, այս
    կետում տրված Ձեր հավաստումների իսկությունը, ուստի սույն կետի յուրաքանչյուր
    խախտման ամբողջական պատասխանատվությունը ընկնում է Ձեզ վրա:
    <br />
    <br />
    6. Ընկերության կողմից մատուցվող Ծառայություններից ողջ ծավալով օգտվելու համար
    Դուք պետք է գրանցվեք Կայքում և ստեղծեք Անձնական էջ (այսուհետ նաև՝
    Օգտագործողի անձնական էջ կամ Անձնական էջ): Յուրաքանչյուր Օգտագործող կարող է
    ունենալ մեկ Անձնական էջ: Օգտագործողներն իրավունք չունեն օգտագործել այլ
    Օգտագարծողի Անձնական էջը: Անձնական էջը բացելիս Օգտագործողը պարտավոր է
    ճշմարիտ ներկայացնել պահանջվող ողջ տեղեկատվությունը: Օգտագործողներն իրենք են
    պատասխանատու իրենց Անձնական էջի մուտքի տվյալների պահպանման համար և իրենք են
    կրում իրենց Անձնական էջով կատարվող գործառնությունների/գործողությունների
    համար պատասխանատվություն: Օգտագործողը պարտավոր է անհապաղ տեղեկացնել
    Ընկերությանը, եթե վստահ է, որ իր Անձնական էջ եղել է չթույլատրված մուտք: Նման
    դեպքում Ընկերությունը իրավունք ունի անմիջապես արգելափակել Անձնական էջը ՝
    մինչև հանգամանքների ամբողջական պարզումը: Ամեն դեպքում Ընկերությունը չի կրում
    ոչ մի պատասխանատվություն այլ անձանց կողմից Օգտագործողի Անձնական էջով
    կատարված գործողությունների համար: Այդ գործողություններով Ընկերությանը կամ
    երրորդ անձանց պատճառված վնասի համար պատասխանատվություն է կրում Օգտագործողը:
    <br />
    <br />
    7. Ընկերության կողմից մատուցվող Ծառայությունները ներառում են սակայն չեն
    սահմանափակվում հետևյալով՝
    <br />
    <ul>
      <li>
        Ընկերության միջոցով գնված ապրանքների տեղափոխման կազմակերպում
        Հաճախորդներին:
      </li>
      <li>
        Հաճախորդների կողմից ինքուրույն գնված ապրանքների տեղափոխության
        կազմակերպում:
      </li>
      <li>Հաճախորդների ապրանքների պահեստավորում:</li>
    </ul>
    8. Ընկերությունն իրավունք ունի իր հայեցողությամբ մերժել Ծառայության
    մատուցումը յուրաքանչյուր ֆիզիկական կամ իրավաբանական անձին դրա կատարման
    հնարավորության բացակայության դեպքում:
    <br />
    <br />
    9. Ընկերությունն իրավունք ունի այն ժամանակահատվածում, երբ պատվերների
    ընդհանուր ծավալը մեծանում է (տոնական օրեր, զեղչային ծրագրեր կամ
    միջոցառումներ և այլն) հետաձգել պատվերների առաքման ժամկետները, ինչպես նաև
    կասեցնել որոշակի Ծառայությունների մատուցումը:
    <br />
    <br />
    10. Պատվերը տեղ է հասցվում ուղեկցող փաստաթղթի վրա նշված հասցեով և հանձնվում
    է Պատվերը ստացողին կամ նրա կողմից լիազորված անձին` ստորագրությամբ: Պատվերն
    ստացող անձը հաստատում է ստացումը և ուղեկցող փաստաթղթի վրա նշում`
    <br />
    <ul>
      <li>անունը և ազգանունը,</li>
      <li>ստորագրությունը,</li>
      <li>Պատվերի հանձնման օրը և ժամը:</li>
      <li>Պատվերի ամբողջականության և լիարժեքության վերաբերյալ գրավոր նշում</li>
    </ul>
    11. Պատվերները ստացողին (օրինական ներկայացուցչին) հանձնելու անհնարինության
    դեպքում դրանք 30 օր պահպանվում են Ընկերության պահեստում: Ուղարկողը կամ
    ստացողը պայմանագրով կարող է երկարաձգել Պատվերի պահպանման ժամկետները:
    Պատվիրատու վճարում է Պատվերի պահպանման համար: Այն դեպքում, եթե Պատվերը չի
    ընդունվում հասցեատիրոջ կողմից 30 օրվա ընթացքում և բացակայում է համապատասխան
    պայմանագիրը պահպանման ժամկետը երկարացնելու համար, Ընկերությունը իրավունք
    ունի օտարել Պատվերը, իրեն հարմար գնով, ստացված գումարից պահել իր կողմից
    կատարված բոլոր ծախսերը, իսկ մնացած գումարը վերադարձնել Հաճախորդին կամ
    հանձնել նոտարի կամ փաստաբանի դեպոզիտ:
    <br />
    <br />
    12. Պատվերը հանձնվում է, եթե առկա է անձը հաստատող փաստաթուղթ, վարորդական
    վկայական, զինգրքույկ:
    <br />
    <br />
    13. Ծառայությունների մատուցման պարտականությունները չկատարելու կամ ոչ պատշաճ
    կատարելու դեպքում, Հաճախորդներն իրավունք ունեն Ընկերությանը կամ վերջինիս
    գործընկեր կազմակերպությանը ներկայացնել գրավոր պահանջ (բողոք):
    <br />
    <br />
    14. Գրավոր պահանջում (բողոքում) նշվում է`
    <br />
    <ul>
      <li>
        դիմումատուի (օրինական ներկայացուցչի) անձը հաստատող փաստաթղթի տվյալները,
      </li>
      <li>պահանջի (բողոքի) էությունը:</li>
    </ul>
    15. Ընկերությունն ուսումնասիրում է գրավոր պահանջները (բողոքները) և դրանց
    գրավոր պատասխանում օրենսդրությամբ սահմանված ժամկետներում:
    <br />
    <br />
    16. Ընկերությունը պատասխանատվություն չի կրում Կայքի միջոցով երրորդ անձանց
    կողմից առաջարկվող և մատուցվող ծառայությունների, ապրանքների և աշխատանքների
    համար:
  </>
);

const confidentialityConditionsText = (
  <>
    Սույն քաղաքականությունը տարածվում է բոլոր այն այցելուների, օգտատերերի և այլ
    անձանց վրա, ովքեր օգտվում են Enosoul.am կայքի (այսուհետ՝ Կայք)
    ծառայություններից կամ նման ծառայություններից օգտվելու իրավունք ունեն:
    Նպատակն է տեղեկացնել, թե կայքում գրանցվելու համար ինչ տվյալներ են
    հավաքագրվում, մշակվում և ինչպես են դրանք օգտագործվում: Սույն
    քաղաքականությունը ցանկացած ժամանակ առանց նախնական ծանուցման կարող է
    միակողմանի փոփոխվել: Փոփոխված տարբերակը կհրապարակվի Կայքում՝ նշելով վերջին
    փոփոխության ամսաթիվը: Նոր պայմաններն իրավական ուժ կունենան Կայքում
    տեղադրվելու պահից և կտարածվեն դրանից հետո ծագած պարտավորությունների վրա:
    Կայքի այցելուների, օգտատերերի պարտականությունն է ժամանակ առ ժամանակ այցելել
    Կայք և ծանոթանալ փոփոխություններին:
    <br />
    <br />
    1. Այցելելով Կայք, Դուք տալիս եք Ձեր համաձայնությունը Ձեր մուտքագրած
    անձնական տվյալները սույն քաղաքականությանը համաձայն մեր Կայքի կողմից
    մշակվելու եվ օգտագործվելու համար, հակառակ դեպքում Դուք իրավունք չունեք
    օգտվել Կայքում մատուցվող ծառայություններից:
    <br />
    <br />
    2. Անգործունակ կամ 18 տարին չլրացած անձինք (բացառությամբ օրենսդրությամբ
    սահմանված կարգով լրիվ գործունակ ճանաչված լինելու դեպքի) իրավունք չունեն
    օգտվել Կայքի կողմից մատուցվող ծառայություններից: Օգտատերերի կողմից
    մուտքագրվող տեղեկությունների իսկությունը ստուգելու հնարավորության
    բացակայության պատճառով, պատասխանատվություն չենք կրում նման անձանց կողմից
    Կայքից և մեր կողմից մատուցվող ծառայություններից օգտվելու հետևանքների համար
    եվ սույն կետի յուրաքանչյուր խախտման ամբողջական պատասխանատվությունը ընկնում է
    Ձեզ վրա:
    <br />
    <br />
    3. Անձնական տվյալներ եվ այլ տեղեկատվություն
    <br />
    <br />
    4. Ձեր կողմից տրամադրված տեղեկությունների օգտագործման եղանակները
    <br />
    <ul>
      <li>Ձեզ հետաքրքրող ծառայություններ և ապրանքներ առաջարկելու համար,</li>
      <li>Սպասարկում իրականացնելու համար,</li>
      <li>
        Տեխնիկական բարդությունների կամ Կայքից օգտվելու դժվարությունների դեպքում
        Ձեզ աջակցելու համար,
      </li>
      <li>
        Նորությունների, նոր ապրանքների կամ նոր ծառայությունների կամ հատուկ
        առաջարկների մասին Ձեզ տեղեկացնելու համար,
      </li>
      <li>Կայքը, ծառայություններն ու ապրանքները բարելավելու համար,</li>
    </ul>
    5. Անձնական տվյալների պաշտպանությունը
    <br />
    <br />
    Մենք անհրաժեշտ միջոցառումներ ենք իրականացնում Ձեր անձնական տեղեկությունների
    անվտանգությունն ապահովելու համար: Ձեր տրամադրած տեղեկությւոնները պահվում են
    ապահով սերվերների տվյալների բազայում: Ձեր անձնական տվյալները կտրամադրվեն
    Կայքի հետ համագործակցող Ընկերություններին, եթե դրանք անհրաժեշտ են Ձեզ
    մատուցվող ծառայություններն իրականացնելու և դրանց որակը բարելավելու համար
    (օրինակ՝ պատվերների ընդունում և պահեստավորում, առաքում, փոստային կամ
    էլ.փոստային նամակների ուղարկում, տվյալների բազայի վերլուծություն,
    մարքեթինգային ծառայությունների մատուցում, բանկային քարտերով վճարումների
    իրականացման կատարում, հաճախորդների սպասարկում եվ այլն): Այդ անձինք ստանում
    են անհրաժեշտ տվյալներին հասանելիություն իրենց կողմից նշված
    գործառնությունների իրականացման նպատակով և իրավունք չունեն օգտագործել նույն
    տեղեկատվությունը այլ նպատակներով: 6. Գործող օրենսդրություն.
    <br />
    <br />
    Մուտք գործելով Կայք կամ Կայքից օգտվելիս Դուք ընդունում եք, որ Կայք այցելելու
    և օգտվելու հետ կապված բոլոր հարցերը կարգավորվում են Հայաստանի Հանրապետության
    օրենսդրությամբ: Վեճերի առաջացման դեպքում դրանք կարգավորվում են
    բանակցությունների միջոցով, համաձայնության չգալու դեպքում, վեճերը
    կկարգավորվեն ՀՀ օրենսդրությամբ նախատեսված կարգով:
  </>
);

const exchangeReturnText = (
  <>
    <b>Պատշաճ որակի ապրանքի փոխարինում եվ վերադարձ</b>
    <br />
    <br />
    Գնված ապրանքը կարելի է վերադարձնել կամ փոխարինել այլ չափի, ձևի, գույնի կամ
    համանման կոմպլեկտայնության ապրանքով` գնի տարբերության դեպքում անհրաժեշտ
    վերահաշվարկ կատարելով, ապրանքը Գնորդին հանձնելու օրվանից 14 օրվա ընթացքում,
    բացառությամբ ներքոնշյալ դեպքերի և կառավարության սահմանած անվանացանկով
    ապրանքների:
    <br />
    Պատշաճ որակի ոչ պարենային ապրանքը փոխարինվում կամ վերադարձվում է, եթե նշված
    ապրանքը չի օգտագործվել, պահպանված են դրա ապրանքատեսքը, սպառողական
    հատկանիշները, կապարակնիքները, ապրանքային պիտակները, ինչպես նաև սպառողին
    տրված վճարման փաստը հավաստող փաստաթուղթը կամ այլ հաշվարկային փաստաթուղթ, և
    առկա են ապրանքը հենց այդ վաճառողից ձեռք բերելու մասին ապացույցներ:
    <br />
    <br />
    <b>Պատշաճ որակի ապրանքը չի կարող վերադարձվել՝</b>
    <br />
    <br />
    ա) եթե ապրանքների պատրաստումը և մատակարարումը իրականացվել են գնորդի հատուկ
    պահանջներին համապատասխան, կամ ապրանքները անհատականացված են,
    <br />
    բ) երբ մատակարարվում են ապրանքներ, որոնք ունեն արագ փչացման, քայքայման կամ
    որակի փոփոխության հատկություն,
    <br />
    գ) կապարակնքված ապրանքների վաճառքի դեպքում, որոնք վերադարձման ենթակա չեն
    առողջապահական կամ հիգիենիկ նկատառումներից ելնելով, և որոնք մատակարարվելուց
    հետո ապակապարակնքվել են գնորդի կողմից.
    <br />
    դ) ապրանքների վաճառքի դեպքում, որոնց մատակարարումից հետո, իրենց բնույթից
    ելնելով, անքակտելիորեն միախառնվում են միմյանց հետ,
    <br />
    ե) կապարակնքված ձայնային կամ տեսաձայնային ձայնագրության կամ կապարակնքված
    ծրագրային ապահովման մատակարարման դեպքում, որոնք մատակարարելուց հետո
    ապակապարակնքվել են գնորդի կողմից,
    <br />
    զ) թերթերի, ամսագրերի, գրքերի կամ այլ տպագիր նյութերի մատակարարման դեպքում,
    <br />
    է) աճուրդում կամ բորսայում կնքված պայմանագրերի առկայության դեպքում.
    <br />
    ը) թվային կրիչի վրա չմատակարարվող թվային բովանդակության մատակարարման
    դեպքում, երբ դրա կատարումն սկսվել է սպառողի անվերապահ նախնական
    համաձայնությամբ, ըստ որի` սպառողն այդպիսով կորցնում է իր վերադարձման
    իրավունքը,
    <br />
    <br />
    <b>Ոչ պատշաճ որակի ապրանքի փոխարինում եվ վերադարձ</b>
    <br />
    <br />
    Ոչ պատշաճ որակի ապրանքի վերաբերյալ պահանջ ստանալու դեպքում վաճառողն իրավունք
    ունի իրականացնելու ապրանքի որակի ստուգում, որի արդյունքները կներկայացվեն
    Ձեզ: Տեխնիկապես բարդ ապրանքների նկատմամբ Գնորդի` ապրանքը վերադարձնելու,
    նմանօրինակ նմուշի կամ այլ նմուշի ապրանքով փոխարինելու պահանջները ենթակա են
    բավարարման` ապրանքում էական խախտումների հայտնաբերման դեպքում: Այդպիսի
    ապրանքների անվանացանկը հաստատում է կառավարությունը:
    <br />
    Գնորդի կողմից ոչ պատշաճ որակի ապրանքի ձեռքբերման դեպքում, որի համար
    սահմանված է պիտանիության ժամկետ, վաճառողը պարտավոր է այդ ապրանքը փոխարինել
    պատշաճ որակի ապրանքով կամ սպառողին վերադարձնել նրա վճարած գումարը, եթե
    ապրանքի թերությունները հայտնաբերվել են պիտանիության ժամկետի սահմաններում:
    <br />
    Ապրանքի փոխարինումը և վերադարձը կարգավորվում է «Սպառողների իրավունքների
    պաշտպանության մասին» ՀՀ օրենքով:
  </>
);

const aboutTheProjectText = (
  <>
    <p>«Էնոսոուլ» ընկերությունը հիﬓադրվել է 2019 թ Հայաստանում։ </p> <br />
    <p>
      Ընկերությունն իր գործունեության հենց սկզբից մասնագիտացել է գինեգործության
      ոլորտում, ինչը հնարավորություն տվեց Հայաստանում գինեգործներին տրամադրել
      շուկայում Եվրոպական արտադրության սարքավորուﬓերի, տեխնոլոգիական լուծուﬓերի
      և գինեգործական արտադրանքի աﬔնալայն տեսականին։
    </p>
    <br />
    <p>
      Այսօր մենք՝ <br /> <br />
      ... ընկերություն ենք, որն առաջարկում է նորարարական տեխնոլոգիական
      լուծուﬓեր, սարքավորուﬓեր, և նյութեր գինու և խﬕչքների արտադրության
      ոլորտում։
      <br /> <br />
      ․․․ ունենք ժամանակակցի սարքավորումներով զինված և շուկայում գինու
      հետազոտությունների ամենալայն սպեկտրը առաջարկող գինու լաբորատորիա։
      <br /> <br />
      ․․․ ունենք տեխնոլոգների թիմ, որոնցից շատերն օժտված են իրենց ոլորտում
      յուրահատուկ տեխնոլոգիական գիտելիքների ﬔծ պաշարով։
      <br /> <br />
      ․․․ մոտիվացված ենք աﬔն օր աջակցելու ﬔր հաճախորդներին հարմարեցնելով
      լավագույն Եվրոպական լուծուﬓերը Հայաստանում՝ պատվիրատուների անհատական
      արտադրական պայմաններին։
    </p>
  </>
);

const homeBanner = (
  <>
    <h1>ԲԱՐԻ ԳԱԼՈՒՍՏ ENOSOUL</h1>
    <p>
      Մենք առաջարկում ժամանակակից տեխնոլոգիական լուծումներ <br /> գինեգործության
      համար
    </p>
  </>
);

const howWeWorkText = (
  <>
    <h3>Լրացրեք ցանկացած ապրանքի տվյալներ և ուղարկեք մեզ</h3>
    <p>
      Ցանկացած տառատեսակով լրացրեք Ձեզ անհրաժեշտ ապրանքի անվանումը, չափման
      միավորը, քանակությունը (որից կարող է կախված լինել առաջարկվող գինը),
      տեխնիկական կամ այլ նկարագրությունը և, սեղմելով &lt;&lt;ուղարկել&gt;&gt;
      կոճակը, իրականացրեք պատվերը: Անհրաժեշտության դեպքում կարող եք կցել նաև
      նկարներ, ֆայլեր, տեխնիկական գծագրեր և այլ փաստաթղթեր: Եթե ունեք ապրանքների
      պատրաստի ցանկը, որոնք նախատեսում եք պատվիրել, ապա ժամանակ չկորցնելու համար
      կարող եք կցել այն համապատասխան վայրում: Ապրանքի պատվիրման մեծ քանակության
      դեպքում հնարավոր է գնային առաջարկ եւ ապրանքի ներմուծում անմիջապես
      արտադրողից:
    </p>

    <h3>Մենք կհաշվարկենք պատվերի արժեքը 24 ժամվա ընթացքում</h3>
    <p>
      Պատվերը ստանալուց հետո մեր մասնագետները առավել արդյունավետ տարբերակ
      գտնելու համար կուսումնասիրեն մեծածախ շուկան և 24 ժամվա ընթացքում հետադարձ
      հասցեով կուղարկեն գնային առաջարկ, որը կարող եք ընդունել ամբողջությամբ կամ
      մասնակի: Գնումներ կարելի է կատարել
      <br />
      <br />
      Կանխիկ՝ վճարելով անմիջապես առաքիչին,
      <br />
      Օնլայն՝ կայքում նշված տարբերակներից որևէ մեկով,
      <br />
      Փոխանցումով՝ հաշիվ-ապրանքագրի հիման վրա:
      <br />
      <br />
      Գնելու դեպքում հնարավոր է ապրանքի պահեստավորում և առաքում: Մենք
      երաշխավորում ենք մեր կողմից առաջարկվող գների մրցունակությունը` հաշվի
      առնելով ձեռք բերած էքսկլյուզիվ պայմանագրերը ինչպես տեղական արտադրողների,
      այնպես էլ մեծածախ վաճառք իրականացնողների հետ: Պատվերի մեծ քանակության
      դեպքում հնարավոր է նաև արտասահմանյան շուկաների ուսումնասիրություն և
      ապրանքի ներմուծում: Ապրանքների ներմուծման դեպքում Enosoul.am –ի թիմը
      կկազմակերպի ապրանքի գնումը, ներմուծումը, մաքսազերծումը, պահեստավորումը և
      առաքումը:
    </p>

    <h3>Կառավարեք Ձեր գնումները անձնական էջի օգնությամբ</h3>
    <p>
      Կայքում անձնական էջը անհատական տարածություն է, որտեղ կարող եք ստանալ
      հավաստի տեղեկություններ իրականացված գործառույթների մասին: Այնտեղ մուտք
      գործելու համար բավական է գրանցվել կամ մուտքագրվել, որից հետո կստանաք
      կայքում Ձեր գործունեության մասին ամբողջական տեղեկատվություն: Դուք ստանում
      եք մեծ առավելություններ, քանի որ տեսնում եք Ձեր գործարքների մասին ողջ
      տեղեկատվությունը, ինչպես նաև հնարավորություն ունեք արագ ստանալու
      Enosoul.am-ի թիմի աշխատակիցների ծառայությունները և օգնությունը հետադարձ
      կապով: Ընդհանուր առմամբ անձնական էջում կարող եք կատարել վճարումներ, ինչպես
      նաև վերլուծել նախկինում կատարված պատվերների և գնումների վիճակագրությունը:
      Գնումների արխիվը Ձեզ հնարավորություն կտա արագ և առանց դժվարության կրկնել
      այս կամ այն գործողությունը: Հիմնական առավելություններից մեկն էլ այն է, որ
      անձնական էջի միջոցով կարողանում եք անմիջապես կատարել Ձեզ անհրաժեշտ
      փոփոխությունները՝ առանց այցելելու մեր գրասենյակ:
    </p>

    <h3>Մենք անվտանգ կպահեստավորենք Ձեր ապրանքը</h3>
    <p>
      Եթե ունեք ապրանքը, սակայն այն այդ պահին չեք օգտագործելու, մենք առաջարկում
      ենք ապրանքի մասնակի կամ ամբողջական պահեստավորում՝ տրամադրելով ծավալուն
      պահեստային տարածքներ: Պահեստավորում ասելով հասկանում ենք Ձեր ապրանքի
      քանակի և ապահովության համար մեր պատասխանատվությունը: Պահեստավորման
      ընթացքում կատարում ենք պատվերի մաքսիմալ օպերատիվ ընդունում, ապրանքների
      կոնկրետ հաշվարկ, ինչպես նաև հնարավորություն ենք տալիս անմիջապես հետևելու
      պահեստավորման պայմաններին: Երաշխավորում ենք ապրանքների ապահովությունը՝
      շնորհիվ գործող տեսախցիկների և 24 ժամյա հսկողության: Հնարավորություն ենք
      տալիս ցանկացած պահի վերցնել Ձեր ապրանքը՝ համապատասխան վայրում հայտ
      լրացնելով, և մենք կկազմակերպենք ապրանքի առաքումը նշված հասցե կարճ
      ժամանակահատվածում:
    </p>

    <h3>Առաջարկում ենք ապրանքների արագ և ապահով առաքում</h3>
    <p>
      Ձեր կողմից ձեռք բերված, ինչպես նաեւ իպահ հանձնված ապրանքները կարող եք
      ստանալ անմիջապես պահեստից կամ օգտվել Enosoul.am առաքման ծառայությունից:
      Առաքումը կարող է կատարվել Enosoul.am պահեստից դեպի Ձեր հասցե, ցուցասրահ
      կամ պահեստ եւ հակառակ ուղությամբ 1 աշխատանքային օրվա ընթացքում: Մեր
      հաճախորդները կարող են հանդիսանալ բոլոր Enosoul.am համակարգից օգտվողները,
      ինչպես ֆիզիկական անձինք, այնպես էլ իրավաբանական` փոքր, միջին եւ մեծ
      ձեռնարկությունները:
    </p>
  </>
);

const adventagesText = (
  <>
    <h3>Խնայում ենք Ձեր ժամանակը, գումարը և նյարդերը</h3>
    <p>
      Enosoul.am- ից օգտվելը արագ է, պարզ և հարմար: Մեր օգնությամբ կարող եք
      բարձրացնել Ձեր գնումները նոր մակարդակի վրա: Յուրաքանչյուր մարդու համար
      գնումներում ամենակարևորն են ժամանակը, դրամական միջոցները և նյարդերը: Ոչ ոք
      չի ուզում դրանք ծախսել ապարդյուն: Այդ նպատակով էլ Enosoul.am-ի խնդիրն է
      գնային առաջարկ ներկայացնելուց առաջ իրականացնել շուկայի մանրամասն
      ուսումնասիրություն և տրամադրել Ձեզ սպառիչ տեղեկատավություն, որի հիման վրա
      կարող եք կայացնել օպտիմալ որոշում` ընտրելով Ձեզ հարմար տարբերակը: Նման
      մոտեցումը Ձեզ հնարավորություն կտա խուսափելու անհիմն ծախսերից և
      անհանգստություններից:
    </p>

    <h3>Անվճար տրամադրում ենք շուկայական գների վերլուծություն</h3>
    <p>
      Արդյունավետ գնումներ իրականացնելու համար կարևորվում է ծախսերի նվազեցումը,
      ինչն էլ իր հերթին ենթադրում է ապրանքների ձեռք բերում մինիմալ գներով:
      Enosoul.am –ը առաջարկում է շուկայական գների վերլուծության տրամադրում
      անվճար: Շուկայական գների վերլուծությունը թույլ է տալիս հետևելու շուկայական
      գների փոփոխության դինամիկային՝ ապահովելով մեր հաճախորդների համար իրենց
      անհրաժեշտ ապրանքը ամենացածր գնով ձեռք բերելու հնարավորությունը: Ունենալով
      շուկայական գների վերլուծություն՝ ունենում եք տեղեկատվական առավելություն և
      ծախսերը նվազեցնելու մեծ հնարավորություններ:
    </p>

    <h3>
      Հնարավորություն ենք տալիս օգտվել լավագույն մատակարարների առաջարկներից և
      հատուկ պայմաններով պայմանագրերից
    </h3>
    <p>
      Մեր կողմից ձեռք բերված հատուկ պայմանները արտադրողների և մեծածախ վաճառք
      իրականացնողների հետ թույլ են տալիս մեր հաճախորդներին առաջարկել անհրաժեշտ
      ապրանքների լայն տեսականի ամենացածր գներով: Շնորհիվ մշակված զեղչային
      համակարգի՝ կարող եք համոզված լինել, որ նվազեցնում եք Ձեր ծախսերը՝ գնելով
      ապրանքները նվազագույն գներով: Ցածր գները ձևավորվում են մատակարարների հետ
      հատուկ և էքսկլյուզիվ պայմանավորվածությունների հիման վրա, ինչպես նաև
      շնորհիվ մեր կողմից կազմակերպված էֆեկտիվ աշխատանքի և արդյունավետ
      լոգիստիկայի:
    </p>

    <h3>
      Օգնում ենք ընկերություններին, ավելացնել վաճառքի ծավալները եւ ընդգրկվել նոր
      շուկաներում
    </h3>
    <p>
      Յուրաքանչյուր ընկերություն իր առջև նպատակ է դնում ավելացնելու վաճառքների
      ծավալները, ինչպես նաև ներգրավելու նոր շուկաներ: Մուտք գործելով մեր կողմից
      առաջարկվող մեծածախ առևտրի ցանց, Դուք հնարավորություն կունենաք ներկայացնել
      Ձեր ապրանքը ամբողջ Հայաստանի մեծածախ եւ մանրածախ առեվտրի կետերում՝ ինչպես
      սեփական անվանման տակ,այնպես ել մեր ընկերության մասնագետի կողմից առաջարվող
      ապրանքանիշի ներքո: Ապահովում ենք ապրանքանիշի առաջխաղացումը՝ իրականացնելով
      արդյունավետ եւ հաշվարկային թվերի վրա հիմնված գովազդ:
    </p>

    <h3>Անվտանգ պահեստավորում ենք վստահված ապրանքը</h3>
    <p>
      Մեր հաճախորդներին առաջարկում ենք ապրանքների անվտանգ պահպանումը՝
      անվտանգության եւ նորարարական ծրագրերով կահավորված պահեստներում, ինչպես
      նաեւ հատուկ տիպի ապրանքների համար նախատեսված ժամանակակից պահեստային
      տարածքներում: Կազմակերպում ենք օպերատիվ բեռնման եւ բեռնաթափման աշխատանքեր՝
      հատուկ մասնագիտացված տեխնիկայի օգնությամբ: Այս ծառայությունը
      հնարավորություն է տալիս նվազեցնել ապրանքների պահեստավորման հետ կապված
      ծախսերը և համոզված լինել դրանց լիարժեք ապահովության մեջ:
    </p>

    <h3>Ապրանքի առաքումը կատարում ենք արագ և ապահով</h3>
    <p>
      Առաքումը իրականացնում ենք մասնագիտացված բեռնատարներով ցանկացած վայր եւ
      հակառակ ուղությամբ ժամը 8:30-18:00 ընկած ժամանակահատվածում: Առաջարկում ենք
      բեռնափոխադրման 4 " Դուռ-Դուռ,Դուռ-Պահեստ ,Պահեստ-Դուռ եւ Պահեստ-Պահեստ
      տարբերակները: Առաջարկում ենք առաքման 2 փաթեթ "Ստանդարտ (Առաքումը
      իրականացվում է 1-2 օրվա ընթացքում,կախված հեռավորությունից) եւ Արագ
      (առաքումը իրականացվում է 1-2 ժամվա ընթացքում կախված հեռավորությունից):
      Երաշխավորում ենք ապրանքների ապահով առաքումը և ապահովում ենք ճկուն գնային
      քաղաքականություն:
    </p>
  </>
);

const deliveryText = (
  <>
    <h3>Առաքում</h3>
    <p>
      Մենք պատրաստ ենք առաջարկել մեր հաճախորդներին ժամանակակից սպասարկում եւ
      ծառայություն: Իրականացնում ենք ապրանքի ընդունում եւ առաքում, բեռնում եւ
      բեռնաթափում,փաթեթավորում: Ինչպես նաեւ առաջարկում ենք առաքման օպտիմալ
      տարիֆային համակարգ, համաձայն որի ստորեւ նշված գումարից ավել գնումների
      դեպքում առաքումը կիրականացվի անվճար, հակառակ դեպքում կհաշվարկվի ըստ
      սահմանված գնացուցակի: Ապրանքի առաքումը կիրականացվի Ձեր կողմից նախօրոք
      նշված ժամկետներում՝ արագ եւ ապահով, հատուկ բեռնափոխադրման համար նախատեսված
      մեքենաներով: Մենք երաշխավորում ենք ապրանքի ապահովությունը եւ անվտանգ
      տեղափոխումը: Ձեր սպասելիքներին չհամապտասխանության դեպքում երաշխավորում ենք
      ապրանքի, ինչպես նաև վճարված գումարի հետ վերադարձը հինգ աշխատանքային օրվա
      ընթացքում:
    </p>
    <h3>Անվճար առաքման համար սահմանված սանդղակ:</h3>
    <br />
    <table className="I-G-simple-table-mobile">
      <tr>
        <td>
          <strong>Առաքման հասցե</strong>
        </td>
        <td>
          <strong>Արժեք (դրամ)</strong>
        </td>
      </tr>
      <tr>
        <td>Ք.Երեւան</td>
        <td>15,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;50km</td>
        <td>250,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;100km</td>
        <td>400,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;200km</td>
        <td>800,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;300km</td>
        <td>1,200,000</td>
      </tr>
    </table>
    <br /> <br />
    <table className="I-G-simple-table">
      <tr>
        <td>
          <strong>Առաքման հասցե</strong>
        </td>
        <td>
          <strong>Արժեք (դրամ)</strong>
        </td>
      </tr>
      <tr>
        <td>Ք.Երեւան</td>
        <td>15,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;50կմ</td>
        <td>250,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;100կմ</td>
        <td>400,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;200կմ</td>
        <td>800,000</td>
      </tr>
      <tr>
        <td>Մարզեր&lt;300կմ</td>
        <td>1,200,000</td>
      </tr>
    </table>
    <br /> <br />
    <h3>Վճարովի առաքման համար սահմանված սանդղակ:</h3>
    <br />
    <table className="I-G-simple-table-mobile">
      <tr>
        <td>
          <strong>Քաղաք/գյուղ</strong>
        </td>
        <td>
          <strong>Արժեք (դրամ)</strong>
        </td>
      </tr>
      <tr>
        <td>Երևան</td>
        <td>500</td>
      </tr>
      <tr>
        <td>Արարատ</td>
        <td>5200</td>
      </tr>
      <tr>
        <td>Պտղնի</td>
        <td>800</td>
      </tr>
      <tr>
        <td>Մեղրաձոր</td>
        <td>5200</td>
      </tr>
      <tr>
        <td>Նուբարաշեն</td>
        <td>1000</td>
      </tr>
      <tr>
        <td>Թալին</td>
        <td>5700</td>
      </tr>
      <tr>
        <td>Պռոշյան (գյուղ)</td>
        <td>1000</td>
      </tr>
      <tr>
        <td>Հանքավան</td>
        <td>6300</td>
      </tr>
      <tr>
        <td>Աբովյան</td>
        <td>1100</td>
      </tr>
      <tr>
        <td>Ջրաշեն</td>
        <td>6800</td>
      </tr>
      <tr>
        <td>Զվարթնոց</td>
        <td>1100</td>
      </tr>
      <tr>
        <td>Գավառ</td>
        <td>7600</td>
      </tr>
      <tr>
        <td>Ձորաղբյուր</td>
        <td>1100</td>
      </tr>
      <tr>
        <td>Դիլիջան</td>
        <td>7700</td>
      </tr>
      <tr>
        <td>Արամուս</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Սպիտակ</td>
        <td>7900</td>
      </tr>
      <tr>
        <td>Արզնի</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Արթիկ</td>
        <td>8200</td>
      </tr>
      <tr>
        <td>Եղվարդ</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Մարալիկ</td>
        <td>8200</td>
      </tr>
      <tr>
        <td>Էջմիածին</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Արենի</td>
        <td>8700</td>
      </tr>
      <tr>
        <td>Մասիս</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Ազատան</td>
        <td>8900</td>
      </tr>
      <tr>
        <td>Նոր Հաճն</td>
        <td>1300</td>
      </tr>
      <tr>
        <td>Շիրակավան</td>
        <td>9100</td>
      </tr>
      <tr>
        <td>Բյուրեղավան</td>
        <td>1500</td>
      </tr>
      <tr>
        <td>Եղեգնաձոր</td>
        <td>9600</td>
      </tr>
      <tr>
        <td>Կաթնաղբյուր, Կոտայք</td>
        <td>1500</td>
      </tr>
      <tr>
        <td>Խնձորեսկ</td>
        <td>9600</td>
      </tr>
      <tr>
        <td>Աշտարակ</td>
        <td>1600</td>
      </tr>
      <tr>
        <td>Ճամբարակ</td>
        <td>9800</td>
      </tr>
      <tr>
        <td>Նոր Գեղի</td>
        <td>1600</td>
      </tr>
      <tr>
        <td>Վանաձոր</td>
        <td>9800</td>
      </tr>
      <tr>
        <td>Մուղնի, Աշտարակ</td>
        <td>1700</td>
      </tr>
      <tr>
        <td>Ախուրյան</td>
        <td>9900</td>
      </tr>
      <tr>
        <td>Լուսակերտ</td>
        <td>1800</td>
      </tr>
      <tr>
        <td>Գյումրի</td>
        <td>9900</td>
      </tr>
      <tr>
        <td>Գառնի</td>
        <td>2000</td>
      </tr>
      <tr>
        <td>Մարտունի</td>
        <td>10100</td>
      </tr>
      <tr>
        <td>Օշական</td>
        <td>2000</td>
      </tr>
      <tr>
        <td>Վայք</td>
        <td>10900</td>
      </tr>
      <tr>
        <td>Ազատավան</td>
        <td>2100</td>
      </tr>
      <tr>
        <td>Իջևան</td>
        <td>11200</td>
      </tr>
      <tr>
        <td>Արտաշատ</td>
        <td>2100</td>
      </tr>
      <tr>
        <td>Ամասիա</td>
        <td>11600</td>
      </tr>
      <tr>
        <td>Փարպի, Արագածոտն</td>
        <td>2100</td>
      </tr>
      <tr>
        <td>Մեղրուտ</td>
        <td>11700</td>
      </tr>
      <tr>
        <td>Գեղարդ</td>
        <td>2300</td>
      </tr>
      <tr>
        <td>Ստեփանավան</td>
        <td>12300</td>
      </tr>
      <tr>
        <td>Չարենցավան</td>
        <td>2600</td>
      </tr>
      <tr>
        <td>Աշոցք</td>
        <td>13000</td>
      </tr>
      <tr>
        <td>Բյուրական</td>
        <td>2700</td>
      </tr>
      <tr>
        <td>Վարդենիս</td>
        <td>13200</td>
      </tr>
      <tr>
        <td>Ուջան</td>
        <td>2700</td>
      </tr>
      <tr>
        <td>Տաշիր</td>
        <td>13700</td>
      </tr>
      <tr>
        <td>Մեծամոր</td>
        <td>2800</td>
      </tr>
      <tr>
        <td>Ջերմուկ</td>
        <td>14000</td>
      </tr>
      <tr>
        <td>Արզական</td>
        <td>3100</td>
      </tr>
      <tr>
        <td>Ալավերդի</td>
        <td>15100</td>
      </tr>
      <tr>
        <td>Կոշ&nbsp;&nbsp;&nbsp; </td>
        <td>3200</td>
      </tr>
      <tr>
        <td>Նոյեմբերյան</td>
        <td>15100</td>
      </tr>
      <tr>
        <td>Բջնի</td>
        <td>3300</td>
      </tr>
      <tr>
        <td>Բերդ</td>
        <td>16700</td>
      </tr>
      <tr>
        <td>Արագած</td>
        <td>3400</td>
      </tr>
      <tr>
        <td>Սիսիան</td>
        <td>17100</td>
      </tr>
      <tr>
        <td>Արալեզ</td>
        <td>3400</td>
      </tr>
      <tr>
        <td>Գորիս</td>
        <td>19700</td>
      </tr>
      <tr>
        <td>Արմավիր</td>
        <td>3600</td>
      </tr>
      <tr>
        <td>Քարվաճառ</td>
        <td>20000</td>
      </tr>
      <tr>
        <td>Աղվերան </td>
        <td>3700</td>
      </tr>
      <tr>
        <td>Շուշի</td>
        <td>24500</td>
      </tr>
      <tr>
        <td>Հրազդան</td>
        <td>3700</td>
      </tr>
      <tr>
        <td>Կապան</td>
        <td>25300</td>
      </tr>
      <tr>
        <td>Վեդի</td>
        <td>3700</td>
      </tr>
      <tr>
        <td>Քաջարան</td>
        <td>25900</td>
      </tr>
      <tr>
        <td>Ավշար</td>
        <td>3900</td>
      </tr>
      <tr>
        <td>Ստեփանակերտ</td>
        <td>26100</td>
      </tr>
      <tr>
        <td>Ապարան </td>
        <td>4500</td>
      </tr>
      <tr>
        <td>Ասկերան</td>
        <td>29300</td>
      </tr>
      <tr>
        <td>Ծաղկաձոր</td>
        <td>4900</td>
      </tr>
      <tr>
        <td>Մարտունի</td>
        <td>30500</td>
      </tr>
      <tr>
        <td>Կաթնաղբյուր, Արագածոտն</td>
        <td>4900</td>
      </tr>
      <tr>
        <td>Մեղրի</td>
        <td>31300</td>
      </tr>
      <tr>
        <td>Մելիքգյուղ,&nbsp; Արագածոտն</td>
        <td>5000</td>
      </tr>
      <tr>
        <td>Մարտակերտ</td>
        <td>32500</td>
      </tr>
      <tr>
        <td>Սևան</td>
        <td>5100</td>
      </tr>
      <tr>
        <td>Հադրութ</td>
        <td>34900</td>
      </tr>
    </table>
    <table className="I-G-simple-table">
      <tr>
        <td>
          <strong>Քաղաք/գյուղ</strong>
        </td>
        <td>
          <strong>Արժեք (դրամ)</strong>
        </td>
        <td>
          <strong>Քաղաք/գյուղ</strong>
        </td>
        <td>
          <strong>Արժեք (դրամ)</strong>
        </td>
      </tr>
      <tr>
        <td>Երևան</td>
        <td>500</td>
        <td>Արարատ</td>
        <td>5200</td>
      </tr>
      <tr>
        <td>Պտղնի</td>
        <td>800</td>
        <td>Մեղրաձոր</td>
        <td>5200</td>
      </tr>
      <tr>
        <td>Նուբարաշեն</td>
        <td>1000</td>
        <td>Թալին</td>
        <td>5700</td>
      </tr>
      <tr>
        <td>Պռոշյան (գյուղ)</td>
        <td>1000</td>
        <td>Հանքավան</td>
        <td>6300</td>
      </tr>
      <tr>
        <td>Աբովյան</td>
        <td>1100</td>
        <td>Ջրաշեն</td>
        <td>6800</td>
      </tr>
      <tr>
        <td>Զվարթնոց</td>
        <td>1100</td>
        <td>Գավառ</td>
        <td>7600</td>
      </tr>
      <tr>
        <td>Ձորաղբյուր</td>
        <td>1100</td>
        <td>Դիլիջան</td>
        <td>7700</td>
      </tr>
      <tr>
        <td>Արամուս</td>
        <td>1300</td>
        <td>Սպիտակ</td>
        <td>7900</td>
      </tr>
      <tr>
        <td>Արզնի</td>
        <td>1300</td>
        <td>Արթիկ</td>
        <td>8200</td>
      </tr>
      <tr>
        <td>Եղվարդ</td>
        <td>1300</td>
        <td>Մարալիկ</td>
        <td>8200</td>
      </tr>
      <tr>
        <td>Էջմիածին</td>
        <td>1300</td>
        <td>Արենի</td>
        <td>8700</td>
      </tr>
      <tr>
        <td>Մասիս</td>
        <td>1300</td>
        <td>Ազատան</td>
        <td>8900</td>
      </tr>
      <tr>
        <td>Նոր Հաճն</td>
        <td>1300</td>
        <td>Շիրակավան</td>
        <td>9100</td>
      </tr>
      <tr>
        <td>Բյուրեղավան</td>
        <td>1500</td>
        <td>Եղեգնաձոր</td>
        <td>9600</td>
      </tr>
      <tr>
        <td>Կաթնաղբյուր, Կոտայք</td>
        <td>1500</td>
        <td>Խնձորեսկ</td>
        <td>9600</td>
      </tr>
      <tr>
        <td>Աշտարակ</td>
        <td>1600</td>
        <td>Ճամբարակ</td>
        <td>9800</td>
      </tr>
      <tr>
        <td>Նոր Գեղի</td>
        <td>1600</td>
        <td>Վանաձոր</td>
        <td>9800</td>
      </tr>
      <tr>
        <td>Մուղնի, Աշտարակ</td>
        <td>1700</td>
        <td>Ախուրյան</td>
        <td>9900</td>
      </tr>
      <tr>
        <td>Լուսակերտ</td>
        <td>1800</td>
        <td>Գյումրի</td>
        <td>9900</td>
      </tr>
      <tr>
        <td>Գառնի</td>
        <td>2000</td>
        <td>Մարտունի</td>
        <td>10100</td>
      </tr>
      <tr>
        <td>Օշական</td>
        <td>2000</td>
        <td>Վայք</td>
        <td>10900</td>
      </tr>
      <tr>
        <td>Ազատավան</td>
        <td>2100</td>
        <td>Իջևան</td>
        <td>11200</td>
      </tr>
      <tr>
        <td>Արտաշատ</td>
        <td>2100</td>
        <td>Ամասիա</td>
        <td>11600</td>
      </tr>
      <tr>
        <td>Փարպի, Արագածոտն</td>
        <td>2100</td>
        <td>Մեղրուտ</td>
        <td>11700</td>
      </tr>
      <tr>
        <td>Գեղարդ</td>
        <td>2300</td>
        <td>Ստեփանավան</td>
        <td>12300</td>
      </tr>
      <tr>
        <td>Չարենցավան</td>
        <td>2600</td>
        <td>Աշոցք</td>
        <td>13000</td>
      </tr>
      <tr>
        <td>Բյուրական</td>
        <td>2700</td>
        <td>Վարդենիս</td>
        <td>13200</td>
      </tr>
      <tr>
        <td>Ուջան</td>
        <td>2700</td>
        <td>Տաշիր</td>
        <td>13700</td>
      </tr>
      <tr>
        <td>Մեծամոր</td>
        <td>2800</td>
        <td>Ջերմուկ</td>
        <td>14000</td>
      </tr>
      <tr>
        <td>Արզական</td>
        <td>3100</td>
        <td>Ալավերդի</td>
        <td>15100</td>
      </tr>
      <tr>
        <td>Կոշ</td>
        <td>3200</td>
        <td>Նոյեմբերյան</td>
        <td>15100</td>
      </tr>
      <tr>
        <td>Բջնի</td>
        <td>3300</td>
        <td>Բերդ</td>
        <td>16700</td>
      </tr>
      <tr>
        <td>Արագած</td>
        <td>3400</td>
        <td>Սիսիան</td>
        <td>17100</td>
      </tr>
      <tr>
        <td>Արալեզ</td>
        <td>3400</td>
        <td>Գորիս</td>
        <td>19700</td>
      </tr>
      <tr>
        <td>Արմավիր</td>
        <td>3600</td>
        <td>Քարվաճառ</td>
        <td>20000</td>
      </tr>
      <tr>
        <td>Աղվերան</td>
        <td>3700</td>
        <td>Շուշի</td>
        <td>24500</td>
      </tr>
      <tr>
        <td>Հրազդան</td>
        <td>3700</td>
        <td>Կապան</td>
        <td>25300</td>
      </tr>
      <tr>
        <td>Վեդի</td>
        <td>3700</td>
        <td>Քաջարան</td>
        <td>25900</td>
      </tr>
      <tr>
        <td>Ավշար</td>
        <td>3900</td>
        <td>Ստեփանակերտ</td>
        <td>26100</td>
      </tr>
      <tr>
        <td>Ապարան&nbsp;</td>
        <td>4500</td>
        <td>Ասկերան</td>
        <td>29300</td>
      </tr>
      <tr>
        <td>Ծաղկաձոր</td>
        <td>4900</td>
        <td>Մարտունի</td>
        <td>30500</td>
      </tr>
      <tr>
        <td>Կաթնաղբյուր, Արագածոտն</td>
        <td>4900</td>
        <td>Մեղրի</td>
        <td>31300</td>
      </tr>
      <tr>
        <td>Մելիքգյուղ, Արագածոտն</td>
        <td>5000</td>
        <td>Մարտակերտ</td>
        <td>32500</td>
      </tr>
      <tr>
        <td>Սևան</td>
        <td>5100</td>
        <td>Հադրութ</td>
        <td>34900</td>
      </tr>
    </table>
  </>
);

const faqTexts = [
  {
    title: "Ապրանքի ետ վերադարձ",
    description: (
      <>
        Ետ վերադարձը կատարվում է գնման պահից 14 օրվա ընթացքում: Կարող եք կատարել
        ետ վերադարձ առաքված ապրանքը բերելով Enosoul.am-ի գրասենյակ: Անհրաժեշտ է
        ներկայացնել անձնագիր և վաճառքի կտրոն:
      </>
    ),
  },
  {
    title: "ԳՐԱՆՑՈՒՄ",
    description: (
      <>
        <b>Ինչպե՞ս գրանցվել</b>
        <br />
        Գրանցվելու համար սեղմեք գրանցվել կոճակի վրա, խնդրում ենք մուտքագրել
        ստորեւ բերված դաշտերը եվ նորից սեղմել ներքեւի հատվածում, որի վրա գրված է
        գրանցվել, լրացնելուց հետո Ձեր էլ. փոստին կուղարկվի նամակ, գրանցումը
        ակտիվացնելու համար: Համակարգում գրանցումը Ձեզ կօգնի ունենալ անձնական էջ,
        որտեղ հետագայում տեսնել Ձեր գնումների պատմությունը եւ որպես մշտական
        հաճախորդ ստանալ կայքում նորությունների վերաբերյալ տեղեկատվություն:
        <br />
        <br />
        <b>Կարո՞ղ եմ գրանցվել այլ համակարգերի միջոցով</b>
        <br />
        Դուք կարող եք գրանցվել facebook, google+ սոցիալական կայքերի միջոցով:
        <br />
        <br />
        <b>Հնարավո՞ր է արդյոք կատարել գնումներ առանց գրանցվելու</b>
        <br />
        Այո հնարավոր է, Դուք կարող եք բաց թողնել «Գրանցվել» դաշտը եվ կատարել
        գնումներ հյուրի կարգավիճակով, սակայն Ձեր գնումների պատմությունը չի
        պահպանվի համակարգում:
        <br />
        <br />
        <b>Ես մոռացել եմ իմ գրացման գաղտնաբառը</b>
        <br />
        Սեղմեք &lt;&lt;մոռացել եք գաղտնաբառը&gt;&gt; վանդակի վրա եվ կստանաք
        հաղորդագրություն Ձեր էլ.հասցեին:
      </>
    ),
  },
  {
    title: "ԱՊՐԱՆՔԱՏԵՍԱԿԱՆԻ",
    description: (
      <>
        <b>Ինչպե՞ս գտնեմ ինձ անհրաժեշտ ապրանքը</b>
        <br />
        &lt;&lt;Ի՞նչ է Ձեզ հարկավոր&gt;&gt; դաշտում լրացրեք ապրանքի անվանումը ,
        չափման միավորը, քանակությունը: Կարող եք նաև նկարագրել ապրանքը
        համապատասխան բաժնում, ինչպես նաև կցել նկարներ, ֆայլեր կամ գծագրեր:
        Սեղմեք պատվիրել կոճակը և մենք կդիտարկենք պատվերը, այնուհետև հետադարձ
        կապով կուղարկենք գնային առաջարկ:
        <br />
        <br />
        <b>Ինչպե՞ս վճարել</b>
        <br />
        Վճարել կարելի է՝
        <br />
        Կանխիկ՝ վճարելով անմիջապես առաքիչին,
        <br />
        Օնլայն՝ կայքում նշված տարբերակներից որևէ մեկով,
        <br />
        Փոխանցումով՝ հաշիվ-ապրանքագրի հիման վրա:
        <br />
        <br />
        <b>Կարո՞ղ եմ ստանալ հաշիվ-ապրանքագիր</b>
        <br />
        Հաշիվ ապրանքագիր ստանալու համար անհրաժեշտ է նշել լրացումներ դաշտում կամ
        զանգել 096 222322հեռախոսահամարով:
        <br />
        <br />
        <b>Կարո՞ղ եմ վճարել արտասահմանից, արտասահմանյան քարտերով</b>
        <br />
        Վճարումը հնարավոր է իրականացնել աշխարհի ցանկացած հատվածում IDRAM
        համակարգով, VISA, MASTER եվ ARCA (անհրաժեշտ է վիրտուալ գրանցում ունենալ)
        բանկային քարտերով:
      </>
    ),
  },
  {
    title: "ԱՌԱՔՈՒՄ",
    description: (
      <>
        <b>Ի՞նչ ժամկետում կկատարվի առաքումը</b>
        <br />
        Առաքումը կատարվում է 1-2 աշխատանքային օրվա ընթացքում (առաքումները
        սկսվում են 09:00-ից մինչ 21:00 բացառությամբ կիրակի օրերի):
        <br />
        <br />
        <b>Հնարավո՞ր է առաքումը տեւի 2 օրից ավել</b>
        <br />
        2 օրից ավել առաքումները կարող են տեւել բացառիկ դեպքերում, որոնց մասին
        հաճախորդին նախապես կտեղեկացվի
        <br />
        <br />
        <b>Ես ցանկանում եմ , որ առաքումը կատարվի մի քանի օր հետո</b>
        <br />
        Առցանց գնման ժամանակ հնարավոր է ապրանքը առաքել ըստ Ձեր ցանկության 7
        աշխատանքային օրվա ընթացքում, դրա համար անհրաժեշտ է լրացումներում նշել
        հստակ առաքման օրը եվ ժամը:
        <br />
        <br />
        <b>Ինչպիսի՞ն են վճարովի առաքումների պայմանները</b>
        <br />
        Մենք առաջարկում ենք առաքման օպտիմալ տարիֆային համակարգ, համաձայն որի
        որոշակի գումարից ավել գնումների դեպքում առաքումը կիրականացվի անվճար,
        հակառակ դեպքում կհաշվարկվի ըստ սահմանված գնացուցակի: Գնացուցակին
        ծանոթանալու համար այցելեք կայքի ՛՛Մեր մասին՛՛→ ՛՛Ինչպես ենք առաքում՛՛:
        <br />
        <br />
        <b>
          Ես ստացել եմ ապրանքները, և հետո պարզել է, որ կան վնասված կամ թերի
          ապրանքներ
        </b>
        <br />
        Առաքման պահին պետք է ստուգեք ապրանքների որակական և քանակական խնդիրները
        (վնասված, թերի), և այդ դեպքում չեք ընդունում նշված ապրանքը, ապրանքագրի
        մեջ կատարում եք համապատասխան գրառումները, այնուհետև 1 աշխատանքային օրվա
        մեջ ապրանքը փոխարինվում է նորով:
      </>
    ),
  },
];

export default {
  price_offer: "Ստանալ գնային առաջարկ",
  date_new_to_old: "Ամենանոր",
  added: "Ավելացված է",
  verificationCode: "Հաստատման կոդ",
  language_label: "Հայերեն",
  i_need: "Որոնել",
  wish_list: "Ցանկեր",
  cart: "Զամբյուղ",
  log_in: "Մուտք",
  filter: "Ֆիլտր",
  all_categories: "Բոլոր բաժինները",
  about_us: "Մեր մասին",
  catalog: "Կատալոգ",
  business: "Բիզնես",
  become_partner: "Դարձեք գործընկեր",
  vacancy: "Աշխատատեղեր",
  contact_us: "Հետադարձ կապ",
  copyright: "enosoul.am Բոլոր իրավունքները պաշտպանված են",
  contract: "Պայմանագիր",
  faq: "FAQ",
  faq_texts: faqTexts,
  confidentiality_conditions: "Գաղտնիության քաղաքականություն",
  products_exchange_and_return: "Ապրանքների փոխարինում և վերադարձ",
  download_app: "Ներբեռնեք հավելվածը",
  download_text: "Գրանցվեք և ստացեք 5000 iBonus",
  not_a_member: "Գրանցված չե՞ք",
  sign_up: "Գրանցվել",
  email: "Էլ. հասցե",
  password: "Գաղտնաբառ",
  restore_password: "Վերականգնել գաղտնաբառը",
  social_login: "Մուտք անձնական էջերով",
  already_a_member: "Գրանցվա՞ծ եք",
  next: "Հաջորդ",
  order: "Ուղարկել հարցումը",
  add: "+ Ավելացնել",
  what_do_you_need_today: "Ի՞նչ է Ձեզ անհրաժեշտ այսօր",
  create_request: "Ստեղծել հարցում",
  category: "Բաժին",
  choose: "Ընտրել",
  quantity: "Քանակ",
  write: "Լրացնել",
  m_u: "Չ/մ",
  description: "Նկարագրություն",
  attach_file: "Կցել ֆայլ",
  phone_number: "Հեռախոսահամար",
  name: "Անուն",
  surname: "Ազգանուն",
  send: "Ուղարկել",
  home_banner: homeBanner,
  add_your_information: "Ավելացնել Ձեր տեղեկատվությունը",
  request_success: "Ձեր հարցումը ուղղարկված է",
  order_create_success: "Ձեր պատվերն ուղղարկված է",
  contact_success: "Ձեր հաղորդագրությունն ուղղարկված է",
  personal_data_success: "Անձնական տվյալները փոխված են",
  phone_verify_success: "Հեռախոսահամարը փոխված է",
  phone_code_send_success: "Ստուգման կոդը ուղարկված է",
  email_send_success: "Ստուգման հղումը ուղարկվել է նշված Էլ. հասցեին",
  checkout_promo_address_error: "Տրամադրեք հասցեն",
  gift_success: "Դուք ունեք նվեր",
  my_orders: "Իմ պատվերները",
  my_requests: "Իմ հարցումները",
  my_companies: "Իմ ընկերությունները",
  my_addresses: "Իմ հասցեները",
  log_out: "Ելք",
  add_new_address: "+ Ավելացնել նոր հասցե",
  add_address: "Ավելացնել հասցե",
  edit_address: "Փոփոխել հասցեն",
  save: "Պահպանել",
  default_address: "Նախանշված հասցե",
  full_name: "Ամբողջական անունը",
  message: "Նամակ",
  promotions: "Հատուկ առաջարկներ",
  address: "Հասցե",
  house: "Բնակարան",
  apartment_office: "Բնակարան/Գրասենյակ",
  contact_text: contactText,
  business_text: businessText,
  contract_text: contractText,
  exchange_return_text: exchangeReturnText,
  confidentiality_conditions_text: confidentialityConditionsText,
  edit: "Փոփոխել",
  remove: "Հեռացնել",
  edit_company: "Փոփոխել ընկերությունը",
  add_company: "Ավելացնել ընկերություն",
  add_new_company: "+ Ավելացնել նոր ընկերություն",
  tin: "ՀՎՀՀ",
  billing_address: "Իրավաբանական հասցե",
  delivery_address: "Մատակարարման հասցե",
  contact_person: "Կոնտակտային անձ",
  add_other_address: "+ Ավելացնել այլ հասցե",
  company_name: "Ընկերության անվանում",
  my_account: "Իմ էջը",
  form: "Հայտ",
  only_files: "Միայն ֆայլեր",
  only_files_request_text:
    "Եթե դուք ժամանակ չունեք հարցումը լրացնելու համար, կարող եք կցել առկա ֆայլեր.",
  about_the_project: "Նախագծի մասին",
  how_we_work: "Ինչպես ենք աշխատում",
  the_advantages_of_cooperation: "Համագործակցության առավելությունները",
  delivery: "Առաքում",
  about_the_project_text: aboutTheProjectText,
  how_we_work_text: howWeWorkText,
  adventages_text: adventagesText,
  delivery_text: deliveryText,
  for_partners: "Գործընկերների համար",
  invitation_to_cooperate: "Համագործակցության հրավեր",
  partners_account_login: "Գործընկերների էջի մուտք",
  help_for_partners: "Օգնություն գործընկերների համար",
  follow_us: "Հետևեք մեզ",
  facebook: "Facebook",
  instagram: "Instagram",
  linked_in: "LinkedIn",
  language: "Լեզու",
  ineed_app: "ineed հավելվածը",
  change_password: "Փոխել գաղտնաբառը",
  personal_data: "Անձնական տվյալներ",
  save_changes: "Պահպանել փոփոխությունները",
  verification: "Ստուգում",
  verification_text: "Խնդրում ենք մուտքագրել հաստատման կոդը, ուղարված",
  ok: "Լավ",
  or: "կամ",
  home: "Գլխավոր էջ",
  send_again: "Ուղարկել կրկին",
  features: "Հատկություններ",
  additional_sales: "Լրացուցիչ վաճառք",
  get: "ստացեք",
  off: "զեղչ",
  discount: "Զեղչ",
  and: "և",
  i_bonus: "iBonus",
  days: "Օր(եր)",
  go_to_checkout: "Գնալ պատվերի հաստատման",
  add_to_cart: "Ավելացնել զամբյուղին",
  add_to_cart_short: "Ավելացնել",
  lists: "Ցանկեր",
  active: "Ակտիվ",
  completed: "Ավարտված",
  request_forms: "Հարցման ձևաչափը",
  request_files: "Հարցման ֆայլերը",
  finished: "Ավարտված",
  canceled: "Չեղարկված",
  best_selling: "Լավագույն վաճառքներ",
  new_products: "Նոր ապրանքներ",
  price_low_to_high: "Արժեք: Ցածրից բարձր",
  price_high_to_low: "Արժեք: Բարձրից ցածր",
  discount_low_to_high: "Զեղչ: Ցածրից բարձր",
  discount_high_to_low: "Զեղչ: Բարձրից ցածր",
  price: "Արժեք",
  brands: "Ապրանքանիշեր",
  min: "Նվազագույն",
  max: "Առավելագույն",
  clear_filters: "Մաքրել Ֆիլտրերը",
  date: "Ամսաթիվ",
  sender: "Ուղարկող",
  add_new_list: "+ Ավելացնել նոր ցանկ",
  payer: "Վճարող",
  add_list: "Ավելացնել ցանկ",
  edit_list: "Փոփոխել ցանկը",
  i: "Ես",
  invite: "Հրավիրել",
  edit_invited_list: "Փոփոխել հրավիրվածների ցանկը",
  delete: "Հեռացնել",
  leave_wish_list: "Դուրս գալ ցանկից",
  no_options: "Ընտրանքներ չկան",
  no_companies: "Ընկերություններ չկան",
  new: "+ Նոր",
  owner: "Պատվիրատու",
  no_lists: "Ցանկեր չկան",
  choose_all_attributes: "Ընտրեք բոլոր ատրիբուտները",
  requested_products: "Հարցված ապրանք(ներ)ը",
  filter_for_users: "Օգտվողների ֆիլտրացում",
  my: "Իմ",
  copy: "Պատճենել",
  copied_to_clipboard: "Պատճենված է փոխանակման բուֆերում",
  url: "URL",
  confirm_description: "Պետք է հաստատեք այս գործողությունը",
  confirm: "Հաստատել",
  user_request_ask: "խնդրում է ավելացնել այս ապրանքը",
  cancel: "Չեղարկել",
  total: "Ընդամենը",
  subtotal: "Ընդհանուր գումար",
  promo_discount: "Promo զեղչ",
  points: "Միավորներ",
  user_points: "Միավորներ",
  checkout: "Պատվերի հաստատում",
  type: "Տեսակ",
  pricing: "Վճարում",
  additionally: "Լրացուցիչ",
  comment: "Մեկնաբանություն",
  products: "Ապրանքներ",
  see_more: "Ցուցադրել ավելին",
  pickup: "Վերցնել",
  sale: "Վաճառք",
  sort_by: "Դասակարգել ըստ",
  show_all: "Ցուցադրել բոլորը",
  search: "Փնտրել",
  popular_categories: "Լավագույն բաժիններ",
  cash: "Կանխիկ",
  card: "Քարտ",
  bank_transfer: "Բանկային փոխանցում",
  delivery_date: "Մատակարարման ամսաթիվ",
  pickup_date: "Վերցնելու ամսաթիվ",
  done: "Հաստատել",
  order_id: "Պատվերի ID",
  status: "Կարգավիճակ",
  repeat: "Կրկնել",
  payment_method: "Վճարման եղանակ",
  product_deleted_warning: "Այս ապրանքը հեռացվել է",
  all_products: "Բոլոր ապրանքները",
  are_you_sure: "Արդյո՞ք վստահ եք",
  written: "Գրված է",
  confirm_password: "Հաստատել գաղտնաբառը",
  choose_password: "Ընտրեք գաղտնաբառը",
  similar: "Միանման",
  sign_up_confirm: (
    <>
      Ես համաձայն եմ{" "}
      <a target="_blank" role="noreferrer" href={ROUTES.EXCHANGE_RETURN}>
        դրույթների
      </a>{" "}
      և{" "}
      <a
        target="_blank"
        role="noreferrer"
        href={ROUTES.CONFIDENTIALITY_CONDITIONS}
      >
        պայմանների
      </a>{" "}
      հետ
    </>
  ),
  reason: "Պատճառ",
  show_more: "Ցույց տալ ավելին",
  promo_code: "Պրոմոկոդ",
  apply: "Կիրառել",
  additional_sale_label: (fromCount: number) => `Գնեք ${fromCount} և ավելի,`,
  checkout_i_bonus_can_use: (bonus: number, points: number): string =>
    `Դուք կարող եք օգտագործել ${bonus} ${Settings.translations.i_bonus} Ձեր ${points}-ից`,
  receiving_bonus: "Ստացած iBonus",
  no_products: "Ապրանքներ չկան",
  no_addresses: "Հասցեներ չկան",
  no_orders: "Պատվերներ չկան",
  no_requests: "Հարցումներ չկան",
  no_notifications: "Ծանուցումներ չկան",
  categories: "Բաժիններ",
  good_morning: "Բարի լույս",
  good_afternoon: "Ողջույն",
  good_evening: "Բարի երեկո",
  show_less: "Ավելի քիչ",
  get_my_order: "Գտնել իմ պատվերը",
  get_my_order_text:
    "Խնդրում ենք գրել ձեր էլ.փոստը և կոդը, պատվերի մանրամասները ստանալու համար",
  code: "Կոդ",
  notifications: "Ծանուցումներ",
  insttallApp: "Ներբեռնել հավելվածը",
  installationBonuses: "Գրանցվեք և ստացեք 5000 iBonus",
  email_or_phone: "Էլ. հասցե կամ հեռախոսահամար",
  install: "Տեղադրել",
  success_partner: "Գործընկերը ավելացավ հաջողությամբ",
  message_sent: "Ձեր հաղորդագրությունը հաջողությամբ ուղարկվեց:",
  send_request: "Հարցում ուղարկել",
  privacy_policy:
    "Շարունակելով ՝ դուք համաձայնվում եք օգտագործման պայմանների հետ և հաստատում եք գաղտնիության քաղաքականությունը",
  businnes_description:
    "Enosoul.am-ում մենք մեր գործընկերների հետ ձգտում ենք աշխատել այնպես, որ կարողանանք հասնենլ նրանց բիզնեսի զարգացման համար անհրաժեշտ արդյունքներին: Ուղարկեք մեզ հարցում և դարձեք մեր գործընկերը",
  wrong_email: "Էլ․ հասցեն սխալ է մուտքագրված",
  wrong_password: "Գաղտնաբառը սխալ է մուտքագրված",
  passwords_do_not_match: "Գաղտնաբառերը չեն համընկնում",
  draft: "Ընթացիկ",
  please_check_correctness_of_email_or_phone_number:
    "Խնդրում ենք ստուգել էլ. փոստի կամ հեռախոսի համարը",

  notification_texts: {
    request_failed: {
      title: "Ձեր հարցումն ավարտված է",
      body: "Ներեցեք, բայց մենք չենք գտել Ձեր կողմից հարցված ապրանքը",
    },
    request_succeeded: {
      title: "Ձեր հարցումն ավարտված է",
      body: "Սեղմեք Ձեր փնտրած ապրանքի մանրամասները տեսնելու համար",
    },
    order_canceled: {
      title: "Ձեր պատվերը չեղարկվել է",
      body: "Սեղմեք մանրամասները տեսնելու համար",
    },
    order_finished: {
      title: "Ձեր պատվերն ավարտված է",
      body: "Սեղմեք մանրամասները տեսնելու եւ բոնուսները ստանալու համար",
    },
    order_idram_failed: {
      title: "Ձեր փոխանցումը չհաջողվեց",
    },
    wish_list_delete: {
      title: "Գնումների ցուցակ է հեռացվել",
      body: "Ցավոք, Ձեր գնումների ցուցակներից մեկը հեռացվել է",
    },
    wish_list_leave: {
      title: "Անդամը լքել է գնումների ցանկը",
      body: "Ցավոք, անդամներից մեկը լքել է գնումների ցանկը",
    },
    wish_list_new_product: {
      title: "Նոր ապրանքատեսակ",
      body: "Նոր ապրանքատեսակ է ավելացվել գնումների ցանկում",
    },
    wish_list_remove_product: {
      title: "Ապրանքատեսակի հեռացում",
      body: "Ապրանքատեսակ է հեռացվել գնումների ցանկից",
    },
    wish_list_product_request: {
      title: "Ապրանքատեսակի ավելացում",
      body: "Գնումների ցանկում օգտատերը ցանկանում է ավելացնել նոր ապրաքատեսակ",
    },
    wish_list_unapprove: {
      title: "Ապրանքատեսակի մերժում",
      body: "Ձեր կողմից հարցված ապրանքատեսակը չի հաստատվել գնումների ցանկում",
    },
    wish_list_approve: {
      title: "Ապրանքատեսակի հաստատոմ",
      body: "Ձեր կողմից հարցված ապրանքատեսակը հաստատվել է գնումների ցանկում",
    },
    wish_list_kick: {
      title: "Գնումների ցանկի անդամակցում",
      body: "Ցավոք, Դուք հեռացվել եք գնումների ցանկից",
    },
    wish_list_new_member: {
      title: "Գնումների ցանկի անդամակցում",
      body: "Նոր անդամ է միացել գնումների ցանկին",
    },
  },
};

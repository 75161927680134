import * as React from "react";
import Settings from "../../../../../platform/services/settings";
import AboutUsImage from "../../../../../assets/images/main.jpg";

const AboutTheProject = React.memo(() => (
  <>
    <div
      className="I-about-image"
      style={{ background: `url("${AboutUsImage}") center/cover` }}
    />
    <div className="I-about-texts">
      <p>{Settings.translations.about_the_project_text}</p>
    </div>
  </>
));

export default AboutTheProject;

export enum LanguageEnum {
  Armenian = 1,
  Russian,
  English,
}
export enum OSTypeEnum {
  Android = 1,
  iOS,
  Web,
};

export enum NotificationTypeEnum {
  NewRequest = 1,
  RequestCanceled,
  RequestFailed,
  RequestSucceeded,
  NewOrder,
  OrderCanceled,
  OrderSetToReview,
  OrderFinished,
  WishListNewProduct,
  WishListRemoveProduct,
  WishListApprove,
  WishListUnapprove,
  WishListLeave,
  WishListDelete,
  WishListProductRequest,
  WishListKick,
  WishListNewMember,
};
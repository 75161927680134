import * as React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../../../../platform/services/settings';
import ROUTES from '../../../../platform/constants/routes';
import ProductController, { IProductSearchItem } from '../../../../platform/api/product';
import Connection from '../../../../platform/services/connection';

import ClickOutside from '../../../../components/click-outside';
import HelperComponent from '../../../../platform/classes/helper-component';

import './style.scss';

interface IState {
  search: string;
  products: IProductSearchItem[];
  open: boolean,
};

interface IProps {
  fieldPosition: any;
  fieldAnimation: any;
  onSearch?(): void;
};

class Search extends HelperComponent<IProps, IState> {

  public state: IState = {
    search: '',
    products: [],
    open: true,
  };
  
  private fetchProducts = async () => {
    const { search } = this.state;

    if (search) {
      Connection.AbortAll();
      const result = await ProductController.Search(search);
      !result.aborted && result.data && this.safeSetState({ products: result.data });
    } else this.safeSetState({ products: [] });
  }

  private clearProducts = () => {
    const { products } = this.state;
    products.length && this.safeSetState({ products: [] });
  }

  private productNavigate = () => {
    const { products } = this.state;
    products.length && this.safeSetState({ products: [], search: '' });
  }

  private focusSearch = () => {
    const { products, search } = this.state;
    !products.length && search && this.fetchProducts();
  }

  private changeSearch = async (e: React.SyntheticEvent<HTMLInputElement>) => {
   if(!this.state.open){
    this.safeSetState({open: true})
   }
    const search = e.currentTarget.value;
    this.safeSetState({ search }, this.fetchProducts);
  }

  private searchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { search } = this.state;
    const { onSearch } = this.props;
    this.safeSetState({open: false})
    if (search.trim()) {
      window.routerHistory.push(`${ROUTES.PRODUCTS.LIST}?search=${search.trim()}`);
      window.dispatchEvent(new CustomEvent('filterchange', { detail: { search } }));
      this.setState({ search: '' }, onSearch && onSearch);
    }
  }


  public render() {
    const { search, products, open } = this.state;

    return (
      <form  className={`I-header-search ${this.props.fieldPosition} `}>
        <ClickOutside onClickOutside={this.clearProducts}>
          <button type="submit" className="icon-search" onClick={this.searchSubmit} />
          <input value={search} placeholder={Settings.translations.i_need} onChange={this.changeSearch} onFocus={this.focusSearch} />
          {!!products.length && open && <div className="I-products">
            {products.map(
              item =>
             <Link
              to={ROUTES.PRODUCTS.DETAILS.replace(':id', item._id)}
              onClick={this.productNavigate}
              key={item._id}
            >{item.name}
            </Link>)}
            <div className="mobile-close-btn" onClick={this.clearProducts}>x</div>
          </div>}
        </ClickOutside>
      </form>
    );
  }
}

export default Search;
import * as React from "react";

import ROUTES from "../../../platform/constants/routes";
import { byRoute } from "../../../platform/decorators/routes";
import Settings from "../../../platform/services/settings";
import { AboutPagesEnum } from "./constants/enums";
import AboutTheProject from "./components/about-the-project";
import HelperPureComponent from "../../../platform/classes/helper-pure-component";

import "./style.scss";

interface IState {
  choosed: AboutPagesEnum;
}

@byRoute(ROUTES.ABOUT)
class AboutUs extends HelperPureComponent<{}, IState> {
  public state: IState = {
    choosed: AboutPagesEnum.AboutTheProject,
  };

  private Content = () => {
    const { choosed } = this.state;

    switch (choosed) {
      case AboutPagesEnum.AboutTheProject:
        return <AboutTheProject />;
      default:
        return <AboutTheProject />;
    }
  };

  public render() {
    return (
      <section className="I-G-page I-about-page">
        <h1 className="I-G-page-title">{Settings.translations.about_us}</h1>
        <div className="I-about-page-content">
          <div className="I-content">
            <this.Content />
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;

import * as React from "react";
import ROUTES from "../../platform/constants/routes";
import { byRoute } from "../../platform/decorators/routes";

import Settings from "../../platform/services/settings";
import Promotions from "./components/promotions";
import Proposal from "./components/proposal";
import BestSelling from "./components/best-selling";
import Brands from "./components/brands";
import MainCategories from "./components/main-categories";
import HelperComponent from "../../platform/classes/helper-component";
import { LanguageEnum } from "../../platform/constants/enums";
import { LanguagesDropdown } from "../../platform/constants/dropdowns";

import Main from "../../assets/images/main.jpg";

import "./style.scss";
import Footer from "../../components/footer/index";

interface IState {
  requestOpen: boolean;
}

@byRoute([ROUTES.HOME.MAIN, ROUTES.HOME.FAIL, ROUTES.HOME.SUCCESS])
class Home extends HelperComponent<{}, IState> {
  public state: IState = { requestOpen: false };

  public bannerImage: string;

  public lang = LanguagesDropdown;

  public armLang = LanguageEnum.Armenian;

  public componentDidMount() {
    this.checkLanguage();
  }

  public checkLanguage = () => {
    if (this.armLang === this.armLang) {
      document.body.classList.add("armenianFont");
    } else {
      document.body.classList.remove("armenianFont");
    }
  };

  public render() {
    return (
      <section className="I-G-page I-home-page">
        <div className="I-home-request">
          <div className="I-home-left">
            <div className="I-clip-div">
              {Settings.translations.home_banner}
            </div>
          </div>
          <div
            className="I-background"
            style={{ background: `url(${Main}) center/cover ` }}
          />
        </div>
        <MainCategories />
        <Promotions />
        <BestSelling />
        <div className="P-home-cover" />
        <Proposal />
        <Brands />
      </section>
    );
  }
}

export default Home;

import * as React from "react";
import { Link } from "react-router-dom";

import { LanguagesDropdown } from "../../platform/constants/dropdowns";
import { IDropdownOption } from "../../platform/constants/interfaces";
import { LanguageEnum } from "../../platform/constants/enums";
import ROUTES from "../../platform/constants/routes";
import Select from "../select";
import Settings from "../../platform/services/settings";

import facebookIcon from "../../assets/images/facebook.png";
import instagramIcon from "../../assets/images/instagram.png";
import Logo from "../../assets/images/logo.png";

import "./style.scss";

class Footer extends React.Component {

  private currentYear = new Date().getFullYear();

  private changeLanguage = (option: IDropdownOption<LanguageEnum>) => {
    Settings.language = option.value;
    window.location.reload();
  };


  public render() {
    return (
      <footer>
        <div className="I-footer-column">
          <img className="logo" src={Logo} alt="" />
        </div>
        <div className="I-footer-column">
          <Link to={ROUTES.ABOUT}>{Settings.translations.about_us}</Link>
          <Link to={ROUTES.CONTACT}>{Settings.translations.contact_us}</Link>
          <Link to={ROUTES.CATALOG}>{Settings.translations.catalog}</Link>
        </div>
        <div className="I-footer-column">
          <Select<LanguageEnum>
            value={Settings.language}
            onChange={this.changeLanguage}
            options={LanguagesDropdown}
            useValue={true}
          />
        </div>
        <div className="I-footer-bottom">
          <h3 className="I-footer-copyright">
            &#9400; {Settings.translations.copyright}, {this.currentYear}
          </h3>
          <div className="social-medias">
            <a
              href="https://www.facebook.com/Enosoul.am/"
              className="social-media"
              target="_blank"
            >
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/enosoul_enosoul/"
              className="social-media"
              target="_blank"
            >
              <img src={instagramIcon} alt="instagram" />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import * as React from 'react';

import ROUTES from '../../platform/constants/routes';
import Settings from '../../platform/services/settings';

const contactText = <>
  Уважаемые клиенты, <br /> <br />
  Мы благодарим Вас за интерес к нашим  услугам. <br />    
  Мы работаем, чтобы улучшить качество  наших услуг, поэтому мы придаем большое значение обратной связи с нашими клиентами. <br />
  В случае возникновения вопросов или проблем, просим заполнить строки ниже или  звонить по телефону <br />
  <b>096 222322</b> <br /> <br />
  <address>
    ООО Эносоул <br />
    0043, РА, г. Ереван, Ширак 9/2 <br />
    Тел: +374 33 08 80 07 <br />
    Эл.почта: info@enosoul.am <br />
  </address>
</>;

const businessText = <>
  <ul>
    <li>Мы полностью берем на себя организацию поставоки и закупки вашей компании.</li>
    <li>Наши работники не только принимают ваш заказ, но и следят за каждым заказом ,начиная от покупки и до доставки.</li>
    <li>Документы, связанные с вашими покупками полностью организует наша команда.</li>
  </ul>
  Наша компания имеет договоры и соглашения  с особыми условиями со многими производителями и продавцами,
  которыми можете воспользоваться также вы, будучи членом нашей команды. Вовлекаясь в предложенную нами оптовую торговую сеть,
  вы будете иметь возможность представить ваш товар во всех оптовых и розничных точках, как под собственным именем,
  так и в качестве предложенной нашими сотрудниками товарной марки.
  Обеспечиваем продвижение товарной марки, осуществляя рекламу, основанную на эффективных и расчетных цифрах.
  Хотите избежать излишних расходов, значит эта служба именно  для вас.
  При заключении с нами договора вы получаете:
  <ul>
    <li>организацию процесса целостного получения необходимого товара, начиная от заказа и до доставки.</li>
    <li>организацию оборота документов</li>
    <li>возможность пользования нашими скидками</li>
    <li>возможность приобретения товаров по оптовым ценам</li>
    <li>при сотрудничестве с нами бесплатно предоставим анализ рыночных цен</li>
    <li>мы предлагаем варианты снижения цен</li>
    <li>при желании предоставляем каталоги и образцы товаров.</li>
  </ul>
  Если вы заинтересованы в наших услугах, позвоните по номеру 011200900, или напишите по адресу  <a href="mailto:business@Enosoul.am">business@Enosoul.am</a> и наши сотрудники любезно ответят вам.
</>;

const contractText = <>
  1. Данный договор (отныне контракт) определяет и регулирует правопорядок оказываемых услуг со стороны ООО “Крома Груп” (отныне также компания) на сайте Enosoul.am (отныне также сайт).<br /><br />
  2. Заходя на сайт, пользуясь любой услугой или вводя любое содержание, целиком или частично ,вы, как пользователь (впредь также пользователь, Вы, Ваш, Вас, Клиент), подтверждаете, что полностью прочитали и поняли контракт, а также другие условия оказания услуг (впредь также “Условия или контракт”) со стороны компании на сайте, и принимаете их, независимо от того вы являетесь клиентом оказываемых услуг этой компании, пользователем сайта или просто посетителем.<br /><br />
  3. Компания имеет право без предварительного уведомления, в одностороннем порядке, в любое время изменить контракт. В этом случае измененный  контракт будет помещен на сайте, с указанием даты последних изменений. При посещении сайта, в обязанности пользователя входит  ознакомление с изменениями в контракте (при наличии таковых). Изменения входят в силу со дня размещения их на сайте, и распространяются на возникшие после этого отношения.<br /><br />
  4. Если вы не согласны с контрактом, необходимо прекратить пользование сайтом .Нахождение на сайте, открытие счета, становление пользователем означает ваше безоговорочное принятие условий контракта и его заключение.<br /><br />
  5. Заключая договор вы подтверждаете, что полностью дееспособны и имеете право пользоваться услугами. Если вы представляете юридическое лицо, то вы свидетельствуете, что вы правомочны пользоваться услугами от имени юридического лица. Тем самым вы принимаете, что компания не может проверить правдивость данных вами сведений в этом пункте, следовательно, ответственность за любое нарушение данного пункта полностью падает на вас.<br /><br />
  6. Для пользования полным объемом оказываемых компанией услуг, вы должны зарегистрироваться на сайте и создать личную страницу(впредь личная страница пользователя или личная страница). Каждый пользователь может иметь одну личную страницу. Один пользователь не имеет права пользоваться личной страницей другого пользователя. При открытии личной страницы пользователь обязан правдиво представить всю требуемую информацию. Пользователи сами ответственны за сохранение данных входа на свою личную страницу и сами несут ответственность за действия на личной странице. В случае, если произошел неразрешенный вход на личную страницу пользователя, он обязан незамедлительно проинформировать компанию. В подобном случае компания имеет право закрыть личную страницу до полного выяснения обстоятельств. В любом случае компания не несет никакой ответственности за действия, совершенные другими лицами на личной странице пользователя. Ответственность за причинение ущерба компании или другим лицам своей деятельностью несет пользователь.<br /><br />
  7. Предоставленные компанией услуги включают, но не ограничиваются следующим;<br />
  <ul>
    <li>Организация транспортировки  купленных при помощи  компании товаров клиентам.</li>
    <li>Организация транспортировки товаров ,купленных клиентами самостоятельно.</li>
    <li>Складирование товаров  покупателей.</li>
  </ul>
  8. Компания имеет право отказать в оказании услуг любому физическому или юридическому лицу, на свое усмотрение в случае отсутствия возможности их выполнения.<br /><br />
  9. Компания, в период, когда увеличивается общий объем заказов(праздники, скидочные программы или мероприятия) имеет право отсрочить доставки, а также приостановить предоставление определенных услуг.<br /><br />
  10. Заказ доставляется по адресу, указанному на сопутствующем документе и вручается заказчику или его полномочному представителю с подписью. Получатель подтверждает получение и на сопутствующем документе указывает;<br />
  <ul>
    <li>имя и фамилию</li>
    <li>подпись</li>
    <li>дату и время доставки заказа</li>
    <li>письменное указание о полноценности и целостности заказа.</li>
  </ul>
  11. При невозможности сдачи заказа получателю (законному представителю) товар хранится в течение  30 дней на складе компании. Отправитель или получатель с помощью договора может продлить срок хранения заказа. В случае если  в течение 30 дней заказ не принимается, а соответствующий договор о продлении хранения отсутствует, компания имеет право отклонить заказ, удержать расходы из полученной суммы, по цене, удобной ей, а остальную сумму вернуть клиенту или сдать в депозит нотариусу или адвокату.<br /><br />
  12. Заказ сдается при наличии документа,удостоверяющего личность,водительского права или военного билета.<br /><br />
  13. При невыполнении услуг,либо выполнении недолжным образом,клиент имеет право предъявить компании или партнерской организации письменную претензию.<br /><br />
  14. В письменной претензии отмечаются;<br />
  <ul>
    <li>Данные документов, подтверждающих личность заявителя(законного представителя)</li>
    <li>Суть требования</li>
  </ul>
  15. Компания изучает письменные требования и жалобы и отвечает в срок,утвержденный законодательством.<br /><br />
  16. Компания не несет ответственности за предлагаемые ,оказанные услуги,товары и работы со стороны третьих лиц на сайте.
</>;

const exchangeReturnText = <>
  <b>Обмен и возврат товара с надлежащим качеством</b><br /><br />
  Купленный товар можно вернуть или  заменить  на товар другого размера, формы, цвета или подобной комплектации, производя необходимый перерасчет в случае разницы в цене, в течение 14 дней со дня вручения товара покупателю, кроме нижеуказанных случаев и товаров, по порядку, установленному правительством.
  Непродовольственный товар с надлежащим качеством заменяется или возвращается, если данный товар не использовался , сохранил товарный вид, потребительское качество, пломбы, бирки, подтверждающие оплату документы, как доказательство того, что покупка осуществлена у данного продавца.<br /><br />
  <b>Товар с надлежащим качеством не может быть возвращен:</b><br /><br />
  а) если изготовление товара и его снабжение осуществлены по особому заказу потребителя, либо товары персонифицированы.<br />
  б) когда продаются быстропортящиеся товары, разрушающиеся,  либо меняющие качество.<br />
  в) в случае продажи товаров, которые после поставки, исходя из своей природы, неразрывно сливаются друг с другом.<br />
  д) в случае пломбированных  аудио или видео записей или пломбированных программ со стороны покупателя.<br />
  е) в случае поставок газет, журналов, книг или других печатных изданий<br />
  ж) при соглашениях, подписанных на аукционах и биржах<br />
  з) при цифровых поставках, не соответствующих  цифровому носителю, когда осуществление этого произошло на основе предварительного соглашения с покупателем, по которому он теряет свое право возврата.<br /><br />
  <b>Возврат или обмен товара с ненадлежащим качеством.</b><br /><br />
  При получении товара с ненадлежащим качеством, продавец имеет право провести экспертизу качества товара, результаты, которые будут представлены вам. В технически сложных случаях, требование покупателя о возврате или обмене на подобный образец, или другой товар, может быть удовлетворено при обнаружении  в товаре существенных нарушений.
  При приобретении покупателем товара с ненадлежащим качеством, продавец обязан заменить данный товар на товар с надлежащим качеством либо вернуть покупателю заплаченную сумму, если недостатки товара обнаружены в течение срока годности.
  Обмен или возврат товара регулируется законодательством Республики Армения «О защите прав потребителя».
</>;

const confidentialityConditionsText = <>
  Данная политика распространяется на всех тех посетителей, пользователей и других лиц, которые пользуются услугами сайта Enosoul.am (далее сайт), либо имеют право пользоваться подобными услугами. Намереваемся проинформировать, какие данные собираются, обрабатываются и как они используются для регистрации на сайте. Данная политика в любое время, без предварирельного осведомления может быть изменена  односторонне. Измененный вариант будет оглашен на сайте, с указанием последней даты поправки .Новые условия получат юридическую силу с момента помещения на сайте и распространятся на возникшие после этого обязательства. Обязанностью посетителей сайта является ознакомление с его изменениями и посещение сайта время от времени.<br /><br />
  1. Посещая сайт, вы даете свое согласие на использование и обработку личных данных, внесенных вами на сайт. Согласно данной политике , в обратном случае вы не имеете права пользоваться оказанными на сайте услугами.<br /><br />
  2. Недееспособные, либо лица, не достигшие 18 лет(исключая случаи полного признания дееспособности в законодательном порядке), не имеют права пользоваться оказанными на сайте услугами. При отсутстствии возможности проверки подлинности вводимых пользователем данных, мы не несем ответственности за последствия при пользовании нашими услугами, и, при любом нарушении данного пункта, ответственность полностью лежит на вас.<br /><br />
  3. Личные данные и другая информация. Вы можете посетить наш сайт без регистрации, однако для полного пользования услугами сайта, вы обязаны зарегистрироваться на сайте. Личные данные собираются только тогда, когда вы их предоставляете. Для регистрации вы должны написать ваше имя, адрес, адрес электронной почты и контактные данные. Во время посещения сайта собирается также некоторая информация о вашем компьютере; IP адрес, наименование браузера, вид компьютера, технические данные пользования, а также средства установления связи с нашим сайтом, такие, как, вид операционной системы, наименование представляющей интернет услуги организации, а также другие подобные данные. Собираемая информация дает возможность вычислить количество посетителей, создать статистику о посещении страниц .Сайт использует cookies.Это цифробуквенные строки, которые создаются при посещении сайта и копируются  в вашем компьютере. Они не собирают личную информацию. С их помощью пользование сайтом становится намного проще и удобнее.<br /><br />
  4. Способы использования предоставленной вами информации;<br />  
  <ul>
    <li>Для предложения заинтересовавших вас товаров и услуг</li>
    <li>Для осуществления  услуг</li>
    <li>Содействие вам, в случае возникновения технических сложностей на сайте</li>
    <li>Для сообщения вам новостей, новых товаров или услуг, а также особых предложений.</li>
    <li>Для улучшения сайта, услуг и товаров.</li>
  </ul>
  5. Защита личных данных<br /><br />
  Мы осуществляем необходимые мероприятия  для обеспечения безопасности ваших личных данных. Предоставленная вами информация хранится в базе надежных серверов. Ваши личные данные будут предоставлены сотрудничающим на сайте организациям ,если это необходимо для осуществления оказываемых вам услуг и улучшения их качества(например; получение заказов и складирование ,доставка, отправка почтовых и электронных писем, анализирование базовых данных ,оказание маркетинговых услуг, осуществление оплат банковыми картами, обслуживание посетителей, и т.д.)Эти лица получают доступ к информации с целью осуществления указанных операций и не имеют права использовать эти данные в других целях.
  6. Действующее законодательство.<br /><br />
  Заходя на сайт или пользуясь им, вы принимаете, что все вопросы, связанные с посещением и пользованием сайта, регулируются законодательством Республики Армения. При возникновении споров, они регулируются с помощью переговоров, при разногласиях споры будут разрешены в порядке, установленном законодательством Республики Армения.
</>;

const aboutTheProjectText = <>
  <p>Добро пожаловать в интернет-магазин Enosoul! Немного о нас.</p>
  <p>Мы пришли на рынок онлайн-торговли РА с новыми революционными подходами.</p>
  <p>Мы представляем САМЫЙ БОЛЬШОЙ ассортимент стоматологических материалов и
оборудования по ЛУЧШИМ ЦЕНАМ.</p>
  <p>Ведущие мировые бренды в области стоматологии, представленные на одной платформе.
Закажите понравившийся товар онлайн или просто позвоните нам по телефону 096 22 23 22.</p>
<p>В интернет-магазине Enosoul вы можете совершать безопасные онлайн-транзакции с помощью
различных способов оплаты. Сайт позволяет оплачивать как наличными, так и совершать покупки
через платежные системы ARCA, MasterCard, Visa, Mobidram, iDram и POS-терминал.</p>
<p>Мы являемся официальным представителем многих ведущих европейских компаний в Армении.
Как представитель мы можем предоставить вам самое лучшее. Акции, скидки, предложения,
бонусы.</p>

</>;

const homeBanner = <>
  <h2>Добро пожаловать в интернет-магазин Enosoul</h2>
  <p>Мы пришли на рынок онлайн-торговли РА <br/> с новыми революционными подходами</p>
</>;

const howWeWorkText = <>
  <h3>Заполните данные о любом товаре и отправьте нам</h3>
  <p>В любой письменной форме заполните название необходимого вам товара, единицу измерения, количество(от чего может зависеть предлагаемая цена), техническая или другая характеристика, и, нажимая клавишу “ отправить” осуществите заказ. При необходимости можете прикрепить фотографию, файлы, технические чертежи и другие документы. Если у вас есть готовый список товаров, которые вы намереваетесь заказать, то можете список приложить в соответствующем месте, чтобы выиграть время. При заказах в большом количестве ценовое предложение  и импортировка  товара непосредственно от производителя.</p>

  <h3>Мы вычислим стоимость заказа в течение 24 часов</h3>
  <p>После получения заказа, для нахождения более продуктивного варианта, наши специалисты изучат оптовый рынок, и в течение 24 часов по обратному адресу отправят предложение, которое вы можете принять полностью либо частично. Покупки можно произвести: наличными, оплачивая непосредственно курьеру, онлайн, по одному из указанных на сайте способов. При покупке товара возможно его складирование(хранение) и доставка. Мы гарантируем конкурентоспособность предложенных нами цен, учитывая приобретенные эксклюзивные договоры, как с местными производителями, так и с оптовиками. При заказе в больших количествах возможно также изучение иностранных рынков и импорт товара. При ввозе товаров команда Enosoul.am осуществит покупку, импорт, растаможивание, складирование и доставку товара.</p>
  
  <h3>Управляйте вашими покупками при помощи личной страницы</h3>
  <p>На сайте личная страница – это персональная территория, где вы можете получить достоверные сведения об осуществляемых действиях. Для входа достаточно зарегистрироваться или записаться, после чего вы можете получить цельную информацию о вашей деятельности на сайте. Вы получаете большие привилегии, так как видите  всю информацию о вашей деятельности, а также имеете возможность быстрого получения помощи и услуг  команды Enosoul.am обратной связью. Одним словом, вы на своей личной странице можете произвести платежи, а также проанализировать статистику заказов и покупок, произведенных ранее. Архив покупок вам позволит быстро  и без труда повторить ту или иную процедуру. Одной из основных привилегий является то, что при помощи личной страницы можете сразу сделать необходимые поправки, не заходя в наш офис.</p>

  <h3>Мы надежно сохраним ваш товар</h3>
  <p>Если имеете товар, однако в данный момент не используете его, мы предлагаем его полное или частичное хранение в объемных складских помещениях. Под хранением мы понимаем ответственность за надежность и количество вашего товара. При складировании производим максимально оперативный прием заказа, конкретный подсчет товара, а также даем возможность проследить за условиями хранения. Гарантируем надежность товаров, благодаря действующим 24 часа камерам видеонаблюдения. Даем возможность снятия товара в любое время, заполняя заявку в соответствующем разделе, после чего  мы осуществим доставку товара по указанному адресу в короткий срок.</p>

  <h3>Предлагаем быструю и надежную доставку товаров</h3>
  <p>Приобретенный вами, а также сданный на хранение товар можете получить непосредственно со склада, или воспользоваться службой доставки Enosoul.am.Доставка может быть осуществлена со склада Enosoul.am до вашего адреса, выставочного зала или склада, а также в обратном направлении в течении одного рабочего дня. Нашими клиентами могут стать все пользователи сайта Enosoul.am, как физические лица, так и юридические; большие, маленькие, средние организации. Мы предлагаем оптимальную тарифную систему доставки, по которой, при сумме, превышающей нижеуказанную, доставка осуществляется бесплатно, в других случаях расчет осуществляется по прейскуранту. Доставка осуществится в указанные вами заранее сроки, быстро, надежно, машинами, специально предусмотренными для перевозок. Мы гарантируем надежность и безопасность перевозок. При несоответствии вашим ожиданиям, гарантируем возврат товара, а также заплаченной суммы в течение 5 рабочих дней.</p>
</>;

const adventagesText = <>
  <h3>Экономим ваше время, деньги и нервы</h3>
  <p>Очень удобно, просто и быстро пользование услугами Enosoul.am. С нашей  помощью можете поднять уровень ваших покупок. Для любого человека самое главное в покупках –это время, деньги и нервы. Никто не хочет тратить их впустую. Целью Enosoul.am является осуществление детального изучения рынка и предоставление исчерпывающей информации вам перед предложением цен, и на основе этого вы можете принять оптимальное решение, выбрав удобный вам вариант.</p>

  <h3>Бесплатно предоставлем вам анализ рыночных цен</h3>
  <p>Для продуктивного осуществления покупок важно снижение расходов, что в свою очередь предполагает приобретение товаров по минимальной цене. Enosoul.am предлагает бесплатное предоставление анализа рыночных цен, что позволяет следить за динамикой изменений на рынке, предоставляя клиентам возможность приобретения  необходимого им товара по минимальной цене. Имея анализ рыночных цен вы приобретаете  информационное преимущество  и большую возможность снижения расходов. Предоставляем возможность пользоваться  предложениями наилучших поставщиков, а также договорами с особыми условиями. Приобретенные  нами специальные условия с производителями и оптовиками, позволяют нам предложить  клиентам широкий ассортимент необходимых товаров по наименьшей цене .Благодаря выработанной скидочной системе, можете быть уверены, что снижаете ваши расходы, покупая товар по минимальной цене. Низкие цены формируются на основе специальных и эксклюзивных соглашений с поставщиками, а также благодаря организованной эффективной работе и логистики с нашей стороны.</p>
  
  <h3>Даем возможность воспользоваться лучшими предложениями поставщиков и контрактами имеющих специальные условия</h3>
  <br /><br />

  <h3>Помогаем компаниям в увеличении объемов и в вовлечении в новые рынки</h3>
  <p>Каждое предприятие ставит цель перед собой увеличить объемы продаж, а также привлечь новые рынки. Войдя в предложенную нами оптовую сеть продаж, вы имеете возможность представить ваш товар во всех розничных и оптовых точках Армении, как под  собственным именем, так и в качестве предложенной нашими сотрудниками товарной марки. Обеспечиваем продвижение товарной марки,осуществляя рекламу, основанную на эффективных и подсчитанных цифрах.</p>

  <h3>Безопасно раскладываем доверенный товар</h3>
  <br /><br />

  <h3>Доставку товара осуществляем быстро и надежно</h3>
  <p>Доставку осуществляем специализированными грузовыми машинами в любое место и в обратном направлении, в течение периода от 08.30 до18.00. Предлагаем 4 вида перевозок; ”Дверь-дверь; Дверь-склад; Склад-дверь; Склад-склад”. БПредлагаем 2 пакета услуг доставки “Стандартная» (доставка осуществляется в течение 1-2 дней, в зависимости от расстояния), и “Быстрая”(доставка осуществляется в течение 1-2 часов, в зависимости от расстояния). Гарантируем доставку товара в сохранности и обеспечиваем гибкую ценовую политику.</p>
</>;

const deliveryText = <>
  <h3>Доставка</h3>
  <p>Мы предлагаем нашим клиентам современный сервис и обслуживание. Мы осуществляем прием продукции и доставку, погрузку и разгрузку, упаковку. Мы также предлагаем доставку с оптимальной тарифной системой, в рамках которой закупки превышающие сумму, установленную ниже будут доставлены бесплатно, в противном случае будет рассчитываться в соответствии с прайс-листом. Продукт будет поставляться в сроки, указанные Вами заранее, быстро и надежно, специальнымы машинамы. Мы гарантируем безопасность продукта и безопасной транспортировки. Если продукт не соответствует вашим  ожиданиям, мы гарантируем возврат продукта, а также возврат уплаченной суммы в течение пяти рабочих дней.</p>

  <h3>Шкала бесплатной доставки</h3><br />
  <table className="I-G-simple-table">
    <tr>
      <td><strong>Адрес  доставки</strong></td>
      <td><strong>Стоимость покупки (драм)</strong></td>
    </tr>
    <tr>
      <td>г.Ереван</td>
      <td>15,000</td>
    </tr>
    <tr>
      <td>области&lt;50km</td>
      <td>250,000</td>
    </tr>
    <tr>
      <td>области&lt;100km</td>
      <td>400,000</td>
    </tr>
    <tr>
      <td>области&lt;200km</td>
      <td>800,000</td>
    </tr>
    <tr>
      <td>области&lt;300km</td>
      <td>1,200,000</td>
    </tr>
  </table><br /> <br />

  <table className="I-G-simple-table-mobile">
    <tr>
      <td><strong>Адрес  доставки</strong></td>
      <td><strong>Стоимость покупки (драм)</strong></td>
    </tr>
    <tr>
      <td>г.Ереван</td>
      <td>15,000</td>
    </tr>
    <tr>
      <td>области&lt;50km</td>
      <td>250,000</td>
    </tr>
    <tr>
      <td>области&lt;100km</td>
      <td>400,000</td>
    </tr>
    <tr>
      <td>области&lt;200km</td>
      <td>800,000</td>
    </tr>
    <tr>
      <td>области&lt;300km</td>
      <td>1,200,000</td>
    </tr>
  </table><br /> <br />
  

  <h3>Установленный прейслист доставок</h3><br />
  <table className="I-G-simple-table-mobile">
    <tr>
      <td><strong>Город/ село</strong></td>
      <td><strong>Стоимость доставки /драм/</strong></td>
    </tr>
    <tr>
      <td>г.Ереван</td>
      <td>500</td>
    </tr>
    <tr>
      <td>Арарат</td>
      <td>5200</td>
    </tr>
    <tr>
      <td>Птгни</td>
      <td>800</td>
    </tr>
    <tr>
      <td>Меградзор</td>
      <td>5200</td>
    </tr>
    <tr>
      <td>Нубарашен</td>
      <td>1000</td>
    </tr>
    <tr>
      <td>Талин</td>
      <td>5700</td>
    </tr>
    <tr>
      <td>Прошян/село</td>
      <td>1000</td>
    </tr>
    <tr>
      <td>Анкаван</td>
      <td>6300</td>
    </tr>
    <tr>
      <td>Абовян</td>
      <td>1100</td>
    </tr>
    <tr>
      <td>Джрашен</td>
      <td>6800</td>
    </tr>
    <tr>
      <td>Звартноц</td>
      <td>1100</td>
    </tr>
    <tr>
      <td>Гавар</td>
      <td>7600</td>
    </tr>
    <tr>
      <td>Дзорахпюр</td>
      <td>1100</td>
    </tr>
    <tr>
      <td>Дилиджан</td>
      <td>7700</td>
    </tr>
    <tr>
      <td>Арамус</td>
      <td>1300</td>
    </tr>
    <tr>
      <td>Спитак</td>
      <td>7900</td>
    </tr>
    <tr>
      <td>Арзни</td>
      <td>1300</td>
    </tr>
    <tr>
      <td>Артик</td>
      <td>8200</td>
    </tr>
    <tr>
      <td>Егвард</td>
      <td>1300</td>
    </tr>
    <tr>
    <td>Маралик</td>
      <td>8200</td>
    </tr>
    <tr>
      <td>Ечмиадзин</td>
      <td>1300</td>
    </tr>
    <tr>
      <td>Арени</td>
      <td>8700</td>
    </tr>
    <tr>
      <td>Масис</td>
      <td>1300</td>
    </tr>
    <tr>
      <td>Нор Ачн</td>
      <td>1300</td>
    </tr>
    <tr>
    <td>Ширакаван</td>
      <td>9100</td>
    </tr>
    <tr>
      <td>Бюрегаван</td>
      <td>1500</td>
    </tr>
    <tr>
    <td>Егегнадзор</td>
      <td>9600</td>
    </tr>
    <tr>
      <td>Катнахпюр, Котайк</td>
      <td>1500</td>
    </tr>
    <tr>
    <td>Хндзореск</td>
      <td>9600</td>
    </tr>
    <tr>
      <td>Аштарак</td>
      <td>1600</td>
    </tr>
    <tr>
    <td>Чамбарак</td>
      <td>9800</td>
    </tr>
    <tr>
      <td>Нор Геги</td>
      <td>1600</td>
    </tr>
    <tr>
    <td>Ванадзор</td>
      <td>9800</td>
    </tr>
    <tr>
      <td>Мугни, Аштарак</td>
      <td>1700</td>
    </tr>
    <tr>
    <td>Ахурян</td>
      <td>9900</td>
    </tr>
    <tr>
      <td>Лусакерт</td>
      <td>1800</td>
    </tr>
    <tr>
    <td>Гюмри</td>
      <td>9900</td>
    </tr>
    <tr>
      <td>Гарни</td>
      <td>2000</td>
    </tr>
    <tr>
    <td>Мартуни</td>
      <td>10100</td>
    </tr>
    <tr>
      <td>Ошакан</td>
      <td>2000</td>
    </tr>
    <tr>
    <td>Вайк</td>
      <td>10900</td>
    </tr>
    <tr>
      <td>Азатаван</td>
      <td>2100</td>
    </tr>
    <tr>
    <td>Иджеван</td>
      <td>11200</td>
    </tr>
    <tr>
      <td>Арташат</td>
      <td>2100</td>
    </tr>
    <tr>
    <td>Амасиа</td>
      <td>11600</td>
    </tr>
    <tr>
      <td>Парпи, Арагацотн</td>
      <td>2100</td>
    </tr>
    <tr>
    <td>Мегрут</td>
      <td>11700</td>
    </tr>
    <tr>
      <td>Гегард</td>
      <td>2300</td>
    </tr>
    <tr>
    <td>Степанаван</td>
      <td>12300</td>
    </tr>
    <tr>
      <td>Чаренцаван</td>
      <td>2600</td>
    </tr>
    <tr>
    <td>Ашоцк</td>
      <td>13000</td>
    </tr>
    <tr>
      <td>Бюракан</td>
      <td>2700</td>
    </tr>
    <tr>
    <td>Варденис</td>
      <td>13200</td>
    </tr>
    <tr>
      <td>Уджан</td>
      <td>2700</td>
    </tr>
    <tr>
    <td>Ташир</td>
      <td>13700</td>
    </tr>
    <tr>
      <td>Мецамор</td>
      <td>2800</td>
    </tr>
    <tr>
    <td>Джермук</td>
      <td>14000</td>
    </tr>
    <tr>
      <td>Арзакан</td>
      <td>3100</td>
    </tr>
    <tr>
    <td>Алаверди</td>
      <td>15100</td>
    </tr>
    <tr>
      <td>Кош&nbsp;&nbsp;&nbsp; </td>
      <td>3200</td>
    </tr>
    <tr>
    <td>Ноемберян</td>
      <td>15100</td>
    </tr>
    <tr>
      <td>Бжни</td>
      <td>3300</td>
    </tr>
    <tr>
    <td>Берд</td>
      <td>16700</td>
    </tr>
    <tr>
      <td>Арагац</td>
      <td>3400</td>
    </tr>
    <tr>
    <td>Сисиан</td>
      <td>17100</td>
    </tr>
    <tr>
      <td>Аралез</td>
      <td>3400</td>
    </tr>
    <tr>
    <td>Горис</td>
      <td>19700</td>
    </tr>
    <tr>
      <td>Армавир</td>
      <td>3600</td>
    </tr>
    <tr>
    <td>Карвачар</td>
      <td>20000</td>
    </tr>
    <tr>
      <td>Агверан </td>
      <td>3700</td>
    </tr>
    <tr>
    <td>Шуши</td>
      <td>24500</td>
    </tr>
    <tr>
      <td>Раздан</td>
      <td>3700</td>
    </tr>
    <tr>
    <td>Капан</td>
      <td>25300</td>
    </tr>
    <tr>
      <td>Веди</td>
      <td>3700</td>
    </tr>
    <tr>
    <td>Каджаран</td>
      <td>25900</td>
    </tr>
    <tr>
      <td>Авшар</td>
      <td>3900</td>
    </tr>
    <tr>
    <td>Степанакерт</td>
      <td>26100</td>
    </tr>
    <tr>
      <td>Апаран</td>
      <td>4500</td>
    </tr>
    <tr>
    <td>Аскеран</td>
      <td>29300</td>
    </tr>
    <tr>
      <td>Цахкадзор</td>
      <td>4900</td>
    </tr>
    <tr>
    <td>Мартуни</td>
      <td>30500</td>
    </tr>
    <tr>
      <td>Катнахпюр, Арагацотн</td>
      <td>4900</td>
    </tr>
    <tr>
    <td>Мегри</td>
      <td>31300</td>
    </tr>
    <tr>
      <td>Меликгюх,&nbsp; Арагацотн</td>
      <td>5000</td>
    </tr>
    <tr>
    <td>Мартакерт</td>
      <td>32500</td>
    </tr>
    <tr>
      <td>Севан</td>
      <td>5100</td>
    </tr>
    <tr>
    <td>Адрут</td>
      <td>34900</td>
    </tr>
  </table>

  <table className="I-G-simple-table">
    <tr>
      <td><strong>Город/ село</strong></td>
      <td><strong>Стоимость доставки /драм/</strong></td>
      <td><strong>Город/ село</strong></td>
      <td><strong>Стоимость доставки /драм/</strong></td>
    </tr>
    <tr>
      <td>Ереван</td>
      <td>500</td>
      <td>Арарат</td>
      <td>5200</td>
    </tr>
    <tr>
      <td>Птгни</td>
      <td>800</td>
      <td>Меградзор</td>
      <td>5200</td>
    </tr>
    <tr>
      <td>Нубарашен</td>
      <td>1000</td>
      <td>Талин</td>
      <td>5700</td>
    </tr>
    <tr>
      <td>Прошян/село</td>
      <td>1000</td>
      <td>Анкаван</td>
      <td>6300</td>
    </tr>
    <tr>
      <td>Абовян</td>
      <td>1100</td>
      <td>Джрашен</td>
      <td>6800</td>
    </tr>
    <tr>
      <td>Звартноц</td>
      <td>1100</td>
      <td>Гавар</td>
      <td>7600</td>
    </tr>
    <tr>
      <td>Дзорахпюр</td>
      <td>1100</td>
      <td>Дилиджан</td>
      <td>7700</td>
    </tr>
    <tr>
      <td>Арамус</td>
      <td>1300</td>
      <td>Спитак</td>
      <td>7900</td>
    </tr>
    <tr>
      <td>Арзни</td>
      <td>1300</td>
      <td>Артик</td>
      <td>8200</td>
    </tr>
    <tr>
      <td>Егвард</td>
      <td>1300</td>
      <td>Маралик</td>
      <td>8200</td>
    </tr>
    <tr>
      <td>Ечмиадзин</td>
      <td>1300</td>
      <td>Арени</td>
      <td>8700</td>
    </tr>
    <tr>
      <td>Масис</td>
      <td>1300</td>
      <td>Азатан</td>
      <td>8900</td>
    </tr>
    <tr>
      <td>Нор Ачн</td>
      <td>1300</td>
      <td>Ширакаван</td>
      <td>9100</td>
    </tr>
    <tr>
      <td>Бюрегаван</td>
      <td>1500</td>
      <td>Егегнадзор</td>
      <td>9600</td>
    </tr>
    <tr>
      <td>Катнахпюр, Котайк</td>
      <td>1500</td>
      <td>Хндзореск</td>
      <td>9600</td>
    </tr>
    <tr>
      <td>Аштарак</td>
      <td>1600</td>
      <td>Чамбарак</td>
      <td>9800</td>
    </tr>
    <tr>
      <td>Нор Геги</td>
      <td>1600</td>
      <td>Ванадзор</td>
      <td>9800</td>
    </tr>
    <tr>
      <td>Мугни, Аштарак</td>
      <td>1700</td>
      <td>Ахурян</td>
      <td>9900</td>
    </tr>
    <tr>
      <td>Лусакерт</td>
      <td>1800</td>
      <td>Гюмри</td>
      <td>9900</td>
    </tr>
    <tr>
      <td>Гарни</td>
      <td>2000</td>
      <td>Мартуни</td>
      <td>10100</td>
    </tr>
    <tr>
      <td>Ошакан</td>
      <td>2000</td>
      <td>Вайк</td>
      <td>10900</td>
    </tr>
    <tr>
      <td>Азатаван</td>
      <td>2100</td>
      <td>Иджеван</td>
      <td>11200</td>
    </tr>
    <tr>
      <td>Арташат</td>
      <td>2100</td>
      <td>Амасиа</td>
      <td>11600</td>
    </tr>
    <tr>
      <td>Парпи, Арагацотн</td>
      <td>2100</td>
      <td>Мегрут</td>
      <td>11700</td>
    </tr>
    <tr>
      <td>Гегард</td>
      <td>2300</td>
      <td>Степанаван</td>
      <td>12300</td>
    </tr>
    <tr>
      <td>Чаренцаван</td>
      <td>2600</td>
      <td>Ашоцк</td>
      <td>13000</td>
    </tr>
    <tr>
      <td>Бюракан</td>
      <td>2700</td>
      <td>Варденис</td>
      <td>13200</td>
    </tr>
    <tr>
      <td>Уджан</td>
      <td>2700</td>
      <td>Ташир</td>
      <td>13700</td>
    </tr>
    <tr>
      <td>Мецамор</td>
      <td>2800</td>
      <td>Джермук</td>
      <td>14000</td>
    </tr>
    <tr>
      <td>Арзакан</td>
      <td>3100</td>
      <td>Алаверди</td>
      <td>15100</td>
    </tr>
    <tr>
      <td>Кош&nbsp;&nbsp;&nbsp; </td>
      <td>3200</td>
      <td>Ноемберян</td>
      <td>15100</td>
    </tr>
    <tr>
      <td>Бжни</td>
      <td>3300</td>
      <td>Берд</td>
      <td>16700</td>
    </tr>
    <tr>
      <td>Арагац</td>
      <td>3400</td>
      <td>Сисиан</td>
      <td>17100</td>
    </tr>
    <tr>
      <td>Аралез</td>
      <td>3400</td>
      <td>Горис</td>
      <td>19700</td>
    </tr>
    <tr>
      <td>Армавир</td>
      <td>3600</td>
      <td>Карвачар</td>
      <td>20000</td>
    </tr>
    <tr>
      <td>Агверан </td>
      <td>3700</td>
      <td>Шуши</td>
      <td>24500</td>
    </tr>
    <tr>
      <td>Раздан</td>
      <td>3700</td>
      <td>Капан</td>
      <td>25300</td>
    </tr>
    <tr>
      <td>Веди</td>
      <td>3700</td>
      <td>Каджаран</td>
      <td>25900</td>
    </tr>
    <tr>
      <td>Авшар</td>
      <td>3900</td>
      <td>Степанакерт</td>
      <td>26100</td>
    </tr>
    <tr>
      <td>Апаран</td>
      <td>4500</td>
      <td>Аскеран</td>
      <td>29300</td>
    </tr>
    <tr>
      <td>Цахкадзор</td>
      <td>4900</td>
      <td>Мартуни</td>
      <td>30500</td>
    </tr>
    <tr>
      <td>Катнахпюр, Арагацотн</td>
      <td>4900</td>
      <td>Мегри</td>
      <td>31300</td>
    </tr>
    <tr>
      <td>Меликгюх,&nbsp; Арагацотн</td>
      <td>5000</td>
      <td>Мартакерт</td>
      <td>32500</td>
    </tr>
    <tr>
      <td>Севан</td>
      <td>5100</td>
      <td>Адрут</td>
      <td>34900</td>
    </tr>
  </table>

  

</>;

const faqTexts = [{
  title: "Возврат товара",
  description: <>
    Возврат осуществляется в течение 14 дней со дня покупки. Можете вернуть доставленный товар в офисе Enosoul.am. Необходимо представить паспорт и товарный чек.
  </>,
}, {
  title: "Регистрация",
  description: <>
    <b>Как зарегистрироваться.</b><br />
    Для регистрации нажмите на клавишу “регистрация”, впишите нижеуказанные поля и, вновь, нажмите в нижнем отделе “регистрация”, после заполнения  на вашу электронную почту будет отправлено письмо для активации регистрации. Регистрация в системе вам поможет иметь собственную страницу, где впоследствии можете увидеть историю ваших покупок, и, в качестве постоянного покупателя получать информацию о новостях на сайте.<br /><br />
    <b>Могу ли я зарегистрироваться с помощью других систем?</b><br />
    Вы можете зарегистрироваться при помощи социальных сетей: Facebook, Google+.<br /><br />
    <b>Возможно ли сделать покупку без регистрации?</b><br />
    Да, возможно, вы можете пропустить поле “регистрация” и совершить покупки в качестве гостя, однако история ваших покупок не сохранится в системе.<br /><br />
    <b>Я забыл пароль регистрации.</b><br />
    Нажмите на клетку “забыли пароль” и получите сообщение  на ваш электронный адрес.
  </>,
}, {
  title: "Ассортимент товаров",
  description: <>
    <b>Как мне найти необходимый товар?</b><br />
    “Что вам нужно?”, на этом поле заполните наименование товара, единицу измерения, количество. Можно также описать товар в соответствующем разделе, а также прикрепить фотографии, файлы, чертежи. Нажмите  клавишу  “заказать” и мы рассмотрим заказ, и обратно отправим ценовое предложение.<br /><br />
    <b>Как оплатить?</b><br />
    Оплатить можно:<br />
    Наличными, непосредственно  курьеру;<br />
    Онлайн, по одному из указанных на сайте способов;<br />
    Переводом, на основе счет-фактуры.<br /><br />
    <b>Могу ли получить счет-фактуру?</b><br />
    Для получения счет-фактуры необходимо отметить в поле “дополнение”, либо позвонить по номеру 011200900.<br /><br />
    <b>Могу ли заплатить из-за границы, иностранными картами?</b><br />
    Оплату можно произвести в любой точке мира по системе IDRAM,VIZA,MASTER,ARCA картами(необходимо иметь виртуальную регистрацию)
  </>,
}, {
  title: "Доставка",
  description: <>
    <b>В какие сроки производится доставка?</b><br />
    Доставка производится в течение 1-2х рабочих дней(доставки начинаются с 09.00 до21.00,кроме воскресенья).<br /><br />
    <b>Я желаю, чтобы доставку произвели  на несколько дней позже.</b><br />
    При покупке в сети  возможна доставка по вашему желанию в течение 7 рабочих дней, для чего необходимо при заполнении четко указать день и час доставки.<br /><br />
    <b>Каковы условия платной доставки?</b><br />
    Мы предлагаем оптимальную тарифную систему, согласно которой при покупке выше определенной суммы, доставка осуществляется бесплатно, в иных случаях сумма будет вычислена по установленному тарифу .Для ознакомления с прейскурантом, зайдите на сайт “О нас-как мы производим доставку”.<br /><br />
    <b>Я получил товар, а потом выяснилось, что есть повреждения, либо товар оказался неполным.</b><br />
    Во время доставки необходимо проверить качественное и количественное состояние товара(повреждения, неполный товар), и, в этом случае не принимаете данный товар, в счет-фактуре производится соответствующая запись, затем, в течение 1 рабочего дня товар заменяется новым.<br /><br />
  </>,
}];

export default {
  price_offer: "Получить ценовое предложение",
  date_new_to_old: "Новинки",
  added: "Добавлено",
  verificationCode: "Код подтверждения",
  language_label: "Русский",
  i_need: "Искать продукт",
  wish_list: "Списки",
  cart: "Корзина",
  log_in: "Войти",
  filter: "Фильтр",
  all_categories: "Все разделы",
  about_us: "О нас",
  business: "Бизнес",
  become_partner: "Стать партнером",
  vacancy: "Вакансия",
  contact_us: "Связаться с нами",
  copyright: "enosoul.am Все права защищены",
  contract: "Контракт",
  faq: "FAQ",
  faq_texts: faqTexts,
  confidentiality_conditions: "Условия конфиденциальности",
  products_exchange_and_return: "Обмен и возврат товаров",
  download_app: "Загрузите приложения",
  download_text: "Зарегистрируйтесь и получите 5000 iBonus",
  not_a_member: "Не зарегистрированы?",
  sign_up: "Регистрация",
  email: "Эл. адрес",
  password: "Пароль",  
  restore_password: "Восстановить пароль?",
  social_login: "Регистрация с личной страницей",
  already_a_member: "Уже вступил?",
  next: "Следующий",
  order: "Отправить запрос",
  add: "+ Добавить",
  what_do_you_need_today: "Что Вам необходимо сегодня?",
  create_request: "Создать запрос",
  category: "Категория",
  choose: "Выбрать",
  quantity: "Количество",
  write: "Заполнить",
  m_u: "Е/и",
  description: "Описание",
  attach_file: "Прикрепить файл",
  phone_number: "Номер телефона",
  name: "Имя",
  surname: "Фамилия",
  send: "Отправить",
  add_your_information: "Добавьте вашу информацию",
  request_success: "Ваш запрос отправлен!",
  order_create_success: "Ваш заказ отправлен!",
  contact_success: "Ваше сообщение отправлено!",
  personal_data_success: "Личные данные были изменены!",
  phone_verify_success: "Телефон был изменен!",
  phone_code_send_success: "Код подтверждения отправлен!",
  email_send_success: "Ссылка для подтверждения была отправлена на Эл. адрес!",
  checkout_promo_address_error: "Предоставьте адрес",
  gift_success: "У вас есть подарок",
  my_orders: "Мои заказы",
  my_requests: "Мои запросы",
  my_companies: "Мои компании",
  my_addresses: "Мои адреса",
  log_out: "Выйти",
  add_new_address: "+ Добавить новый адрес",
  add_address: "Добавить адрес",
  edit_address: "Редактировать адрес",
  save: "Сохранить",
  default_address: "Адрес по умолчанию",
  full_name: "Полная имя",
  message: "Сообщение",
  promotions: "Специальные предложения",
  address: "Адрес",
  house: "Дом",
  apartment_office: "Квартира/офис",
  contact_text: contactText,
  business_text: businessText,
  contract_text: contractText,
  exchange_return_text: exchangeReturnText,
  confidentiality_conditions_text: confidentialityConditionsText,
  edit: "Редактировать",
  remove: "Удалить",
  edit_company: "Редактировать компанию",
  add_company: "Добавить компанию",
  add_new_company: "+ Добавить новую компанию",
  tin: "ИНН",
  billing_address: "Платежный адрес",
  delivery_address: "Адресс доставки",
  contact_person: "Контактний человек",
  add_other_address: "+ Добавить другой адрес",
  company_name: "Название компании",
  my_account: "Мой аккаунт",
  form: "Форма",
  only_files: "Только файлы",
  only_files_request_text: "Если у вас нет времени, чтобы выполнить запрос, вы можете прикрепить существующие файлы.",
  about_the_project: "О проекте",
  how_we_work: "Как мы работаем",
  the_advantages_of_cooperation: "Преимущества сотрудничества",
  delivery: "Доставка",
  about_the_project_text: aboutTheProjectText,
  how_we_work_text: howWeWorkText,
  adventages_text: adventagesText,
  delivery_text: deliveryText,
  for_partners: "Для партнеров",
  invitation_to_cooperate: "Приглашение к сотрудничеству",
  partners_account_login: "Вход для партнеров",
  help_for_partners: "Помощь для партнеров",
  follow_us: "Подписывайтесь на нас",
  facebook: "Facebook",
  instagram: "Instagram",
  linked_in: "LinkedIn",
  language: "Язык",
  ineed_app: "ineed приложения",
  change_password: "Изменить пароль",
  personal_data: "Личные данные",
  save_changes: "Сохранить изменения",
  verification: "Подтверждения",
  verification_text: "Пожалуйста, напишите проверочный код отправленный на",
  ok: "Хорошо",
  send_again: "Отправить еще раз",
  features: "Характеристики",
  additional_sales: "Дополнительные продажи",
  get: "получите",
  off: "скидку",
  discount: "Скидка",
  and: "и",
  i_bonus: "iBonus",
  days: "День",
  go_to_checkout: "Перейти к оформлению заказа",
  add_to_cart: "Добавить в корзину",
  add_to_cart_short: "Добавить",
  lists: "Списки",
  active: "Активные",
  completed: "Завершенные",
  request_forms: "Форма запроса",
  request_files: "Файл(ы) запроса",
  finished: "Законченный",
  canceled: "Отменен",
  best_selling: "Самые продаваемые",
  new_products: "Новые продукты",
  price_low_to_high: "Цена: От низкой к высокой",
  price_high_to_low: "Цена: От высокой к низкой",
  discount_low_to_high: "Скидка: От низкой к высокой",
  discount_high_to_low: "Скидка: От высокой к низкой",
  price: "Цена",
  brands: "Бренды",
  min: "Минимум",
  max: "Максимум",
  clear_filters: "Очистить Фильтры",
  date: "Дата",
  sender: "Отправитель",
  add_new_list: "+ Добавить новый список",
  payer: "Плательщик",
  home_banner : homeBanner,
  add_list: "Добавить список",
  edit_list: "Редактировать список",
  i: "Я",
  invite: "Приглашение",
  edit_invited_list: "Управлять списком приглашенных",
  leave_wish_list: "Выйти из списка",
  delete: "Удалить",
  no_options: "Нет вариантов",
  no_companies: "Нет компаний",
  new: "+ Новый",
  owner: "Владелец",
  no_lists: "Нет списков",
  choose_all_attributes: "Выберите все атрибуты",
  requested_products: "Запрашиваемый продукт(ы)",
  filter_for_users: "Фильтр пользователей",
  my: "Моя",
  or: "или",
  copy: "Скопировать",
  copied_to_clipboard: "Скопировано в буфер обмена",
  url: "URL",
  confirm_description: "Вам необходимо подтвердить это действие",
  confirm: "Подтвердить",
  user_request_ask: "попросит добавить следующий элемент в этот список",
  cancel: "Отменить",
  total: "Итого",
  subtotal: "Общая сумма",
  promo_discount: "Promo скидка",
  points: "Балли",
  user_points: "Баллов",
  checkout: "Оформить заказ",
  type: "Тип",
  pricing: "Платеж",
  additionally: "Дополнительно",
  comment: "Комментарий",
  products: "Товары",
  see_more: "Показать больше",
  pickup: "Подобрать",
  sale: "Продаж",
  sort_by: "Сортировать по",
  show_all: "Показать все",
  search: "Поиск",
  popular_categories: "Популярные категории",
  cash: "Денежные средства",
  card: "Карта",
  bank_transfer: "Банковский перевод",
  delivery_date: "Дата доставки",
  pickup_date: "Дата заезда",
  done: "Готово",
  order_id: "Номер ID",
  status: "Статус",
  repeat: "Повторить",
  payment_method: "Способ оплаты",
  product_deleted_warning: "Продукт был удален!",
  all_products: "Все продукты",
  are_you_sure: "Вы уверены?",
  written: "Написано",
  confirm_password: "Подтвердите пароль",
  choose_password: "Выберите пароль",
  similar: "Единообразный",
  sign_up_confirm: <>Я согласен с <a target="_blank" role="noreferrer" href={ROUTES.EXCHANGE_RETURN}>тезоми</a> и <a target="_blank" role="noreferrer" href={ROUTES.CONFIDENTIALITY_CONDITIONS}>условиями</a></>,
  reason: "Причина",
  show_more: "Показать больше",
  promo_code: "Промокод",
  apply: "Применять",
  additional_sale_label: (fromCount: number) => `Купите ${fromCount} и больше,`,
  checkout_i_bonus_can_use: (bonus: number, points: number): string => `Вы можете использовать ${bonus} ${Settings.translations.i_bonus} из вашей ${points}`,
  receiving_bonus: "Полученный iBonus",
  no_products: "Нет товаров",
  no_addresses: "Нет адресов",
  no_orders: "Нет заказов",
  no_requests: "Нет запросов",
  no_notifications: "Нет Уведомлений",
  categories: "Категории",
  good_morning: "Доброе утро",
  good_afternoon: "Добрый день",
  home: "Домашняя страница",
  good_evening: "Добрый вечер",
  show_less: "Показать меньше",
  get_my_order: "Найти мой заказ",
  get_my_order_text: "Пожалуйста, напишите код подтверждения, отправленный на",
  code: "Код",
  notifications: "Уведомления",
  insttallApp: "Скачать приложение",
  installationBonuses: "Зарегистрируйтесь и получите 5000 iBonus",
  install: "Утановить",
  success_partner: 'Партнер успешно добавлен',
  email_or_phone: "Эл. адрес или номер телефона",  
  send_request:"Послать запрос",
  message_sent: 'Ваше сообщение было успешно отправлено.',
  privacy_policy: "Продолжая, Вы соглашаетесь с условиями использования и подтверждаете политику конфиденциальности",
  businnes_description: "В Enosoul.am, мы стремимся работать с нашими клиентами для достижения результатов, необходимых для развития их бизнеса. Отправьте нам запрос и станьте нашим партнером",
  wrong_email: 'Неправильный эл. адрес',
  wrong_password: 'Неправильный пароль',
  passwords_do_not_match: 'Пароли не совпадают',
  draft: 'В процессе',
  please_check_correctness_of_email_or_phone_number: 'Пожалуйста, проверьте правильность адреса электронной почты или номера телефона',
  catalog: "Каталог",

  notification_texts: {
    request_failed: {
      title: "Ваш запрос не выполнен",
      body: "Извините, но мы не смогли найти нужный Вам товар",
    },
    request_succeeded: {
      title: "Ваш запрос выполнен",
      body: "Нажмите, чтобы увидеть детали о найденном товаре",
    },
    order_canceled: {
      title: "Ваш заказ отменен",
      body: "Нажмите, чтобы увидеть детали",
    },
    order_finished: {
      title: "Ваш заказ завершен",
      body: "Нажмите, чтобы увидеть детали и получить бонусы",
    },

    order_idram_failed: {
      title: "Не удалось выполнить платеж",
    },
    
    wish_list_delete: {
      title: "Список покупок удален",
      body: "К сожалению, один из ваших списков покупок был удален его создателем",
    },
    wish_list_leave: {
      title: "Участник вышел из списка желаний",
      body: "К сожалению, участник покинул ваш список желаний",
    },
    wish_list_new_product: {
      title: "Новый продукт",
      body: "Новый продукт был добавлен в список покупок",
    },
    wish_list_remove_product: {
      title: "Продукт удален",
      body: "Продукт был удален из списка покупок",
    },
    wish_list_product_request: {
      title: "Добавление продукта",
      body: "Участник хочет добавить новый продукт в список покупок",
    },
    wish_list_unapprove: {
      title: "Отклонение продукта",
      body: "Запрашиваемый продукт был отклонен в списке покупок",
    },
    wish_list_approve: {
      title: "Одобрение продукта",
      body: "Запрашиваемый продукт был одобрен в списке покупок",
    },
    wish_list_kick: {
      title: "Членство списка покупок",
      body: "К сожалению, вы были удалены из списка желаний",
    },
    wish_list_new_member: {
      title: "Членство списка покупок",
      body: "Новый участник присоединился к списку покупок",
    },
  },
};
